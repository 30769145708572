import React from 'react';

export const Vpn = () =>
{
    return(
        <div  className="loginPage">
        <div className='loginPanel hub-white'>
          Якщо ви бачите цю сторінку, вам потрібно спочатку встановити VPN підключення до мережі, для безпечного користування.
          <br/>
          Після встановлення VPN перейдіть за <a href="/">цим посиланням</a>.
        </div>
      </div>
    );
}
export default Vpn;
