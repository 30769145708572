import React, { Component } from 'react';
import { AuthProvider, useAuth } from './AuthProvider';

export const  ButtonLogout = ()=>{
    var {onLogout} = useAuth();
    return ( 
        <div onClick={onLogout} className="hubTaskBarButton hubLogoutButton">

        </div>
        );
}
 
export default ButtonLogout;