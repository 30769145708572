import React from 'react'
import nl2br from 'react-nl2br'
import moment from 'moment'
import { Accordion, AccordionDetails } from '@mui/material';

export default class ExecSteps extends React.Component
{
   constructor(props) {
      super(props);
   }

   render () {

      const execs = this.props.data.filter(el => el.mi_wfState === 'EXECUTED' && el.executionComment && el.executionComment.length)

      if (!execs) return (null)

      /* execs.forEach(element => {
         const match = element.executionComment.match(/^([\s\S]+)( -- Погоджено в DoMobile)$/) //( -- Погоджено в DoMobile)
         console.log(element.executionComment, match)
         if (match !== null) {
            element.executionComment = match[1]
            element.executionCommentPS = match[2]
         }
      }) */

      const style = {
         marginLeft: '1.5em',
      }

      return (
         execs.map(ex => (
            <Accordion key={ex.ID} sx={style}>
            <AccordionDetails>
            <div key={ex.ID} className="resolutionPanel left">
               <div className='marginLeft'>
                  <span className="bold left">{ex.orgUnitEOSshortFIO}</span>
                  <span> від </span>
                  <span className="bold">{moment(ex.executionDate).format('LL')}</span>
                  { ex.toDocument ? <div>{ex.toDocument}</div> : null }
               </div>
               <div className='marginLeft'>
                  <span className="bold left">Зміст: </span>
                  <span>{nl2br(ex.executionComment)}</span>
                  {/* <em style={{fontSize:'.9em'}}>{nl2br(ex.executionCommentPS)}</em> */}
               </div>
            </div>
            </AccordionDetails>
            </Accordion>
         ))
      )
   }
}
