
const all = [
   'CANCELED',
   'CLOSED',
   'NEW',
   'NOTREGISTERED',
   'ONCOMPLETION',
   'ONCONSIDERATION',
   'ONCORRECTION',
   'ONEXECUTION',
   'ONRECONCILIATION',
   'ONREDACT',
   'ONSENDING',
   'RECONCILED',
   'REGISTERED',
   'RETURNEDFROMRECONCILIATION',
   'RETURNEDFROMREGISTRATION',
   'SENT',
   'TRANSFERRED_TO_DEALS',
   'TWO_SIDE_SIGNING'
]

const processed = ['CANCELED', 'CLOSED', 'SENT', 'TWO_SIDE_SIGNING', 'REGISTERED',]

const notDraft = ['CANCELED', 'CLOSED', 'SENT', 'TWO_SIDE_SIGNING', 'REGISTERED', 'ONEXECUTION', 'TRANSFERRED_TO_DEALS',]

export { processed, notDraft, all }
