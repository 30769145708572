import React from 'react';
import { useNavigate} from 'react-router-dom';

import AlertNotification from './AlertNotification';

export const ErrorContext = React.createContext(null);
export const useError = () => React.useContext(ErrorContext);

export const ErrorProvider = ({children}) =>
{
   const [error,setError] = React.useState();
   const navigate = useNavigate();

   const [alertMsg, alertMsgSet] = React.useState({})

   const handleError = (message)=>{
      if(message.status)
      {
         switch(message.status){
            case 401:
               setError(message.statusText);
               navigate("/login");
               break;
            case 403:
               setError(message.statusText);
               navigate("/login");
               break;
            case 500:
               setError(message.responseText);
               navigate("/error");
               break;
            default:
               setError(message.statusText);
               navigate("/error");
               break;
         }
      }else{
         setError(message);
         navigate("/error");
      }
   }

   /* const handleAlert = (message) => {
      alertMsgSet(message.responseJSON);
   } */

   const handleAlert = React.useCallback(
      (message) => alertMsgSet(message.responseJSON),
      []
   )

   const errorState = {
      error,
      onError: handleError,
      onAlert: handleAlert
   }

   return (
      <ErrorContext.Provider value={errorState}>
         <AlertNotification message={alertMsg}></AlertNotification>
         {children}
      </ErrorContext.Provider>
   );
}
