// Resolution.lib.js

const convertUBQL = (json) => {
	const {
		fields,
		// rowCount,
		data,
		result = []
	} = json.resultData

	data.forEach((item, itemKey) => {
		result[itemKey] = {}
		fields.forEach((field, fieldKey) => {
			result[itemKey][field] = item[fieldKey]
		})
	})
	return result
}

/**
 * Будуємо вкладене (з дітками) дерево резолюцій
 *
 * @param {object[]}  Масив резолюцій (плоский)
 *
 * @returns {object[]}  Масив резолюцій (вкладений)
 */

const getResolutionTree = resolution => {
	if (!resolution || !Array.isArray(resolution)) return (null)

	const makeTree = (nodes, parentId) => {
		 return nodes
			.filter((node) => node.parentResolutionItemID === parentId)
			.reduce(
					(tree, node) => [
						...tree,
						{
							...node,
							children: makeTree(nodes, node.ID),
						},
					],
					[],
			)
		}

	// Витягуємо пункти резолюцій із Структурованої резолюції у загальний масив
	const fullItems = []
	resolution.forEach(node => {
		if ( node.resType === "STRUCTURE" && node.resolutionItem) {
			node.resolutionItem.forEach (item => {
					item.parentResolutionItemID = node.ID
					item.resType = 'SIMPLE'
					item.plannedOrgUnitCaption = `Пункт №${item.itemNumber}`
					item.signerEmail = node.signerEmail
					fullItems.push(item)
			})
			node.execute = []
		}
		fullItems.push(node)
	})
	// this.state.fullFlatResItems = fullItems
	const resTree = makeTree(fullItems, null)
	return resTree
}


/**
 *
 */
const doc_resolution = {}
doc_resolution.getAllowedResolutionTaskType = ({...params}) => {
	const {
		docID,
		resolutionID = null,
		resolutionItemID = null,
		parentResolutionExecutorOrgUnitID = null
	} = params

	const query = {
		"entity": "doc_resolution",
		"method": "getAllowedResolutionTaskType",
		"docID": docID,
		"resolutionID": resolutionID,
		"resolutionItemID": resolutionItemID,
		"parentResolutionExecutorOrgUnitID": parentResolutionExecutorOrgUnitID
	}
	return query
}

doc_resolution.addnew = ({...params}) => {
	const {
		docID,
		parentResolutionItemID,
		considerationTaskID = null
	} = params

	const query = {
		"entity": "doc_resolution",
		"method": "addnew",
		// "lockType": "ltTemp",
		"fieldList": [
			"ID",
			/* "shortText",
			"forMyControl",
			"taskType",
			"executionTerm",
			"excludingWeekends",
			"controlTaskID",
			"periodicalType",
			"periodicalBegin",
			"periodicalEnd",
			"signerID",
			"signDate",
			"makerID",
			"makeDate",
			"executionDate",
			"executionResult",
			"parentResolutionItemID",
			"docID",
			"resType",
			"mi_wfState",
			"mi_modifyDate",
			"mi_owner",
			"parentResolutionItemID.executionTerm",
			"signerID.staffUnitID",
			"makerID.staffUnitID",
			"docID.mi_wfState",
			"docID.executionTerm",
			"parentResolutionItemID.controlTaskID",
			"controlTaskID.executionTerm",
			"considerationTaskID",
			"parentExecutorID" */
		],
		"execParams": {
			"parentResolutionItemID": parentResolutionItemID,
			"docID": docID,
			"considerationTaskID": considerationTaskID
		}
	}
	return query
}

doc_resolution.getResolutionSigner = ({...params}) => {
	const {
		docID,
		parentResolutionItemID = null,
		parentResolutionExecutorOrgUnitID = null,
		considerationTaskID = null,
	} = params

	const query = {
		"entity": "doc_resolution",
		"method": "getResolutionSigner",
		"docID": docID,
		"parentResolutionItemID": parentResolutionItemID,
		"parentResolutionExecutorOrgUnitID": parentResolutionExecutorOrgUnitID,
		"considerationTaskID": considerationTaskID
	}
	return query
}

doc_resolution.delete = (ID) => {
	const query = {
		"entity": "doc_resolution",
		"method": "delete",
		"execParams": {
			"ID": ID
		}
	}
	return query
}

doc_resolution.insert = ({...params}) => {
	const query = {
		"entity":"doc_resolution",
		"method":"insert",
		"fieldList":["shortText","forMyControl","taskType","executionTerm","excludingWeekends","controlTaskID","periodicalType","periodicalBegin","periodicalEnd","signerID","signDate","makerID","makeDate","executionDate","executionResult","parentResolutionItemID","docID","resType","mi_wfState","ID","mi_modifyDate","mi_owner","parentResolutionItemID.executionTerm","signerID.staffUnitID","makerID.staffUnitID","docID.mi_wfState","docID.executionTerm","parentResolutionItemID.controlTaskID","controlTaskID.executionTerm","considerationTaskID","parentExecutorID"],
		"execParams": params
	}
	return query
}

doc_resolution.update = ({...params}) => {
	const query = {
		"entity":"doc_resolution",
		"method":"update",
		"fieldList":["shortText","forMyControl","taskType","executionTerm","excludingWeekends","controlTaskID","periodicalType","periodicalBegin","periodicalEnd","signerID","signDate","makerID","makeDate","executionDate","executionResult","parentResolutionItemID","docID","resType","mi_wfState","ID","mi_modifyDate","mi_owner","parentResolutionItemID.executionTerm","signerID.staffUnitID","makerID.staffUnitID","docID.mi_wfState","docID.executionTerm","parentResolutionItemID.controlTaskID","controlTaskID.executionTerm","considerationTaskID","parentExecutorID"],
		__skipOptimisticLock: true,
		lockType: 'ltTemp',
		"execParams": params,
	}
	return query
}

doc_resolution.select = (ID) => {
	const query = {
		"entity": "doc_resolution",
		"method": "select",
		"fieldList": [
			'ID',
			'considerationTaskID',
			'parentResolutionItemID',
			'mi_wfState',
			'mi_wfState.name',
			'mi_modifyDate',
			'taskType',
			'taskType.name',
			'resType',
			'resType.name',
			'executionTerm',
			'executionDate',
			"periodicalType",
			"periodicalType.name",
			"periodicalBegin",
			"periodicalEnd",
			'mi_createDate',
			'shortText',
			'plannedOrgUnitCaption',
			'signerID',
			'signerID.employeeID.ID', // 4 email
			'signerID.employeeID.shortFIO',
			'docID.docKindID.isNeedSignForResolution',
			'docID.docKindID.resolutionCryptoName',
		],
		"lockType": "ltTemp",
		"ID": ID,
	}
	return query
}

doc_resolution.getDataForSign = (ID) => {
	const query = {
		entity: "doc_resolution",
		method: "getDataForSign",
		ID : ID
	}
	return query
}

doc_resolution.delegate = ({...params}) => {
	const {executorID} = params
	const query = {
		entity: "wf_statechart",
		method: "dispatchSMEvent",
		execParams: {
			ID: executorID,
			event: "delegate",
			entity: "doc_executor",
			executionDate: new Date(),
		}
	}
	return query
}

doc_resolution.revoke = ({...params}) => {
	const {resolutionItemID, comment = null} = params
	const query = {
		entity: "wf_statechart",
		method: "dispatchSMEvent",
		execParams: {
			ID: resolutionItemID,
			event: "revoke",
			entity: "doc_resolutionitem",
			comment: comment,
		}
	}
	return query
}

doc_resolution.restore = (resolutionItemID) => {
	const query = {
		entity: "wf_statechart",
		method: "dispatchSMEvent",
		execParams: {
			ID: resolutionItemID,
			event: "returnToExecution",
			entity: "doc_resolutionitem",
		}
	}
	return query
}

doc_resolution.isLocked = (ID) => {
	const query = {
		entity: "doc_resolution",
		method: "isLocked",
		ID: ID
	}
	return query
}

doc_resolution.unlock = (ID) => {
	const query = {
		entity: "doc_resolution",
		method: "unlock",
		ID: ID
	}
	return query
}


/**
 *
 */
const ubm_enum = {}
ubm_enum.select = (eGroup) => {
	const query = {
		"entity": "ubm_enum",
		"method": "select",
		"fieldList": ["ID", "name", "eGroup", "code"],
		"whereList": {
			"c1": {
				"expression": "[eGroup]",
				"condition": "equal",
				"value": eGroup
			}
		},
		"orderList": {
			"_asc": {
				"expression": "sortOrder",
				"order": "asc"
			}
		},
	}
	return query
}

/**
 *
 */
const doc_resolutiontemplate = {}
doc_resolutiontemplate.select = () => {
	const query = {
		"entity": "doc_resolutiontemplate",
		"method": "select",
		"fieldList": ["ID","caption"],
		"orderList": {
			"caption": {
				"expression": "caption",
				"order":"asc"
			}
		},
		"options": {
			"start":0,
			"limit":100
		}
	}
	return query
}

/**
 *
 */
const doc_resolutionitem = {}
doc_resolutionitem.select = resolutionItemID => {
	const query = {
		"entity": "doc_resolutionitem",
		"fieldList" : ["executionTerm"],
		"method": "select",
		"whereList": {
			"ID": {
				"expression": "[ID]",
				"condition": "equal",
				"values": {
					"ID": resolutionItemID
				}
			}
		}
	}
	return query
}

/**
 *
 */
const doc_execitem = {}
doc_execitem.select = (execItemID) => {
	const query = {
		"entity": "doc_execitem",
		"method": "select",
		"fieldList": ["taskType"],
		"whereList": {
			"c1": {
				"expression": "[ID]",
				"condition": "equal",
				"value": execItemID
			}
		}
	}
	return query
}

/**
 *
 */
const doc_controltask = {}
doc_controltask.select = ({...params}) => {
	const {
		docID,
		taskType
	} = params
	const query = {
		"entity": "doc_controltask",
		"method": "select",
		"fieldList": [
			"ID",
			"caption",
			"executionTerm",
			"taskType",
			"mi_modifyDate"
		],
		"whereList": {
			"docIDFilter": {
				"expression": "[docID]",
				"condition": "equal",
				"value": docID
			},
			"taskTypeFilter": {
				"expression": "taskType",
				"condition": "in",
				"value": taskType
			},
			"controlLevelFilter": {
				"expression": "[controlLevel]",
				"condition": "!=",
				"value": "NONE"
			},
			"controlRemovalDateFilter": {
				"expression": "[controlRemovalDate]",
				"condition": "isNull"
			},
			"controlTaskFilterDate": {
				"expression": "[executionDate]",
				"condition": "isNull"
			},
			"mi_wfStateFilter": {
				"expression": "[mi_wfState]",
				"condition": "in",
				"value": [
					"ONEXECUTION",
					"PROLONGED"
				]
			},
			"controlTaskIDFilter": {
				"expression": "[ID]",
				"condition": "equal",
				"value": "000000"
			}
		},
		"orderList": {
			"_asc": {
				"expression": "caption",
				"order": "asc"
			}
		},
		"logicalPredicates": [
			"([taskTypeFilter] AND [controlLevelFilter] AND [controlRemovalDateFilter] AND [controlTaskFilterDate] AND [docIDFilter] AND [mi_wfStateFilter]) OR [controlTaskIDFilter]"
		],
		"options": {
			"limit": 30,
			"start": 0
		}
	}
	return query
}

/**
 *
 */
const doc_executorroleprops = {}
doc_executorroleprops.select = () => {
	const query = {
		"entity": "doc_executorroleprops",
		"method": "select",
		"fieldList": [
			"ID",
			"execRole",
			"orderIndex",
			"maxQuan"
		],
		"orderList": {
			"orderIndex": {
				"expression": "orderIndex",
				"order": "asc"
			}
		}
	}
	return query
}


/**
 *
 */
const doc_executor = {}
doc_executor.getExecutorsByExecItem = (execItemID) => {
	const query = {
		"entity": "doc_executor",
		"method": "getExecutorsByExecItem",
		"fieldList": [
			"orgUnitID",
			"mi_modifyDate",
			// "mi_unityEntity",
			"respForExecItem",
			"caption",
			"execRole",
			"adjExecutionTerm",
			"adjText",
			// "groupID",
			"executionDate",
			"executionComment",
			"ID",
		],
		"orderList": {
			"role": {
			 "expression": "execRole.sortOrder",
			 "order": "asc"
			}
		},
		"whereList": {
			"byExeecID": {
				"expression": "[execItemID]",
				"condition": "equal",
				"value": execItemID
			}
		},
		"options": {
			"limit": 100,
			"start": 0
		}
	}
	return query
}

doc_executor.addnew = () => {
	const query = {
		"entity": "doc_executor",
		"fieldList": ["ID"],
		"method": "addnew"
	}
	return query
}

doc_executor.delete = (ID) => {
	const query = {
		"entity": "doc_executor",
		"method": "delete",
		"execParams": {
			"ID": ID
		}
	}
	return query
}

doc_executor.insert = ({...params}) => {
	const {
		ID,
		execRole,
		orgUnitID,
		execItemID,
	} = params
	const query = {
		"entity": "doc_executor",
		"method":"insert",
		"fieldList": [
			"ID",
			"execRole",
			"orgUnitID",
			"respForExecItem",
			"execItemID",
		],
		"execParams": {
			"ID": ID,
			"execRole": execRole,
			"orgUnitID": orgUnitID,
			"respForExecItem": true,
			"execItemID": execItemID
		}
	}
	return query
}

/**
 * /ubql?rq=doc_executor.getInfoExecItem
 *
 * @param {Number} ID
 *
 * @returns {
 *		"allowAddExecutor": true,
 *		"allowSignNew": false,
 *		"needSignNew": false,
 *		"allowReturnToExecution": false,
 *		"allowRevoke": true,
 *		"allowCancel": false,
 *		"resolutionID": null,
 *		"showNeedSignResolution": true
 *	}
 */
doc_executor.getInfoExecItem = (ID) => {
	const query = {
		"entity": "doc_executor",
		"method": "getInfoExecItem",
		"execParams": {
			"execItemID": ID
		}
	}
	return query
}

/**
 *
 */
const iit_signature = {}
iit_signature.saveSignature = ({...params}) => {
	const {
		docID,
		resolutionID,
		sign,
		signerName = null,
		serial = null,
	} = params
	const query = {
		entity: "iit_signature",
		method: "saveSignature",
		// sTime: new Date,
		execParams: {
			 docID: docID,
			 resolutionID: resolutionID,
			 signType: "RESOLUTION",
			 signature: sign,
			 // "timeStamp":"2022-08-19T12:50:59Z",
			 serial: serial,
			 signerNameFromCert: signerName,
		}
	}
	return query
}

/**
 * Кнопка "Передати на виконання"
 *
 * @param {number} considerationtaskID
 */
const doc_considerationtask = {}
doc_considerationtask.startExecution = (considerationtaskID) => {
	const query = {
		entity: "wf_statechart",
		method: "dispatchSMEvent",
		execParams: {
			ID: considerationtaskID,
			event: "startExecution",
			entity: "doc_considerationtask",
		}
	}
	return query
}

/**
 * Кнопка "Ознайомитися"
 *
 * @param {number} considerationtaskID
 */
doc_considerationtask.approve = (considerationtaskID) => {
	const query = {
		entity: "wf_statechart",
		method: "dispatchSMEvent",
		execParams: {
			ID: considerationtaskID,
			event: "approve",
			entity: "doc_considerationtask",
		}
	}
	return query
}

/**
 *
 */
const doc_document = {}
doc_document.select = (docID) => {
	const query = {
		"entity": "doc_document",
		"fieldList" : [
			"ID",
			"caption",
			"docType",
			"taskType",
			"recipientID",
			"docKindID.isNeedSignForResolution",
			"mi_modifyDate",
			"executionTerm",
			"mi_unityEntity",
		],
		"method": "select",
		"whereList": {
			"ID": {
				"expression": "[ID]",
				"condition": "equal",
				"values": {
					"ID": docID
				}
			}
		}
	}
	return query
}

doc_document.isLocked = (docID) => {
	const query = {
		entity: "doc_document",
		method: "isLocked",
		ID: docID
	}
	return query
}

doc_document.unlock = (docID) => {
	const query = {
		entity: "doc_document",
		method: "unlock",
		ID: docID
	}
	return query
}


/**
 *
 */
/* const wf_statechart ={}
wf_statechart.dispatchSMEvent = ({...params}) => {
	const query = {
		entity: "wf_statechart",
		method: "dispatchSMEvent",
		execParams: {
			ID: this.props.dataStorageContent.data.currentResolution.executorID,
			event: "delegate",
			entity: "doc_executor",
			executionDate: new Date(),
		}
	}
} */

/* Export block */
export {
	convertUBQL,
	getResolutionTree,
	ubm_enum,
	doc_resolution,
	doc_resolutiontemplate ,
	doc_resolutionitem,
	doc_execitem,
	doc_controltask,
	doc_executorroleprops,
	doc_executor,
	iit_signature,
	doc_considerationtask,
	doc_document,
}
