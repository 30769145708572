import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import LinearProgress from '@mui/material/LinearProgress'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import { doGet } from "./Do";

const SearchFragment = ({...props}) => {
   const {item, query, setSearchDone} = props

   const [dataLoad, setDataLoad] = useState(true)
   const [data, setData] = useState([])

   useEffect(() => {

      const searchedPartResult = item.searchedPartResult ? JSON.parse(item.searchedPartResult) : []
      const partData = []

      const requests = searchedPartResult.map(element => doGet(`ftsContext&docID=${item.docID}&q=${element.word}`))

      Promise.all(requests)
         .then(responses => responses.map(response => partData.push(...response)))
         .then(() => setData(partData))
         .catch(error => console.error(error))
         .finally(() => {
            setDataLoad(false)
            setSearchDone(true)
         })

   }, [item, setSearchDone]);

   return (

      dataLoad ?
         <LinearProgress color="inherit" />
      :
         data.length ?
            data.map((item, key) => (
               <div key={key} className="searchresult" dangerouslySetInnerHTML={{ __html: item.searchedContext }}></div>
            ))
            :
            <>
               <SentimentVeryDissatisfiedIcon sx={{fontSize:'1rem'}} />
               <SentimentVeryDissatisfiedIcon sx={{fontSize:'1rem'}} />
               <SentimentVeryDissatisfiedIcon sx={{fontSize:'1rem'}} />
            </>
   )

}

export default SearchFragment
