import React from "react"
import FormControl from "@mui/material/FormControl"
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import moment from "moment/moment"
import { useSwiper } from "swiper/react"
import nl2br from "react-nl2br"
import { Accordion, AccordionDetails, AccordionSummary, AccordionActions } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import Executor from "./Executor"
import { doc_considerationtask, getResolutionTree } from './Resolution.lib'
import Resolution from "./Resolution"
import { doPost } from './Do'
import { useError } from './ErrorProvider'
import { useLoader } from './Loader'
import AlertDialog from "./AlertDialog"
import { useDataStorage } from './DataStorage'
import Executors from "./Executors"

const ConsiderationPanel = ({...params}) => {

   const {
      data,
      currentUser,
      reloadMainPage /* from Screen.js */
   } = params

   const swiper = useSwiper()
   const dataStorageContext = useDataStorage()

   const [currentTask, currentTaskSet] = React.useState({})
   const [currentResolution, currentResolutionSet] = React.useState({})
   const [showAlertDialog, showAlertDialogSet] = React.useState(false)

   React.useEffect(() => {
      const task = data.considerationTasks.find(el => el.orgUnitID === currentUser.staffUnitID) || data.considerationTasks[0]
      currentTaskSet(task)
   }, [data, currentUser])

   React.useEffect(() => currentResolutionSet(data.resolution.find(el => el.considerationTaskID === currentTask.ID)), [data, currentTask])

   /* React.useEffect(() => {
      console.log("currentResolution=%O", currentResolution)
   }, [currentResolution])
   React.useEffect(() => {
      console.log("currentTask=%O", currentTask)
   }, [currentTask]) */

   const [expanded, setExpanded] = React.useState(true)
   const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false)
   }

   const {onAlert} = useError()
   const loaderContext = useLoader()

   // const accordionRef = React.useRef()

   const taskApprove = () => {
      loaderContext.onWait()
      doPost('wf_statechart.dispatchSMEvent', JSON.stringify([doc_considerationtask.approve(currentTask.ID)]), '/ubql?rq=')
         .then(() => reloadMainPage())
         .catch(error => onAlert(error))
         .finally(() => setTimeout(() => loaderContext.onResume(), 500) )
   }

   //
   const executorProp = React.useMemo(() => {
      return [
         {"MAIN" : 'Головні'},
         {"COEXECUTOR" : 'Співвиконавці'},
         {"INFO" : 'До відома'},
         {"CONTROLLER" : 'Контролер'},
      ]
   }, [])

   //
   ////
   return (
      <Accordion
         TransitionProps={{ unmountOnExit: true }}
         // expanded={expanded === `panel-${currentTask.ID}`}
         onChange={ handleChange(`panel-${currentTask.ID}`) }
         sx={{paddingRight: 0}}
         defaultExpanded={true}
      >
         <AccordionSummary
            // ref={accordionRef}
            id={`panel-${currentTask.ID}-header`}
            aria-controls={`panel-${currentTask.ID}-content`}
            expandIcon={<ExpandMoreIcon />}
            sx={{paddingRight: 0, flexDirection: "row-reverse"}}
         >

      <div className="hubDocDetails">
         <div className='hubDDRight'>

         <div className='hubDescription hub-main-text'>
            {currentTask.orgUnitEOSshortFIO || currentTask.plannedOrgUnitCaption} — {currentTask.mi_wfStateName}
         </div>

            <div className='hubDescription'>
               {data.regNumber} від {moment(data.regDate).format('L')}
            </div>
            <div className="hubDescription">
               <span className="bold left">На розгляд: </span>
               {
                  data.considerationTasks && data.considerationTasks.map((task, index, array) => (
                        <span key={task.ID} className="marginLeft">
                           <Executor
                              my={task.orgUnitID === currentUser.staffUnitID}
                              executor={{...task, caption: task.orgUnitCaption}}
                              last={index === array.length - 1}
                           >
                           </Executor>
                        </span>
                     )
                  )
               }
            </div>
         </div>
      </div>

         </AccordionSummary>

      { // Показ форми тільки якщо задача АКТУАЛЬНА для ДАНОГО співробітника та нема Чорнетки резолюції
         currentTask.mi_wfState === "ONCONSIDERATION" && !currentResolution && (
            <AccordionActions>
            <FormControl fullWidth>
            <Divider />
            <AlertDialog
               open={showAlertDialog}
               setOpen={showAlertDialogSet}
               onConfirm={taskApprove}
            >
            </AlertDialog>

            <Button
               size="small"
               variant="text"
               color="inherit"
               onClick={ () => showAlertDialogSet(true) }
               id={`considerationApprove-${currentTask.ID}-button`}
               sx={{textTransform: 'none', fontSize: '1em', mb: 2, mt: 2}}
            >
               Ознайомлений
            </Button>

            <Button
               size="small"
               variant="text"
               color="inherit"
               onClick={ () => {
                  dataStorageContext.handleData({
                     currentConsideration: {
                        // ID: data.ID,
                        docID: data.ID,
                        considerationTaskID: currentTask.ID
                     }
                  })
                  dataStorageContext.handleData({
                     targetResolution: {}
                  })
                  swiper.slideTo(5) // ConsiderationResolutionPanel
               }}
               id={`considerationStartExecution-${currentTask.ID}-button`}
               sx={{textTransform: 'none', fontSize: '1em'}}
            >
               Проста резолюція
            </Button>
         </FormControl>
         </AccordionActions>
      )}

      <AccordionDetails sx={{paddingRight: '3px', paddingLeft: 1.5}}>


{ // Показ форми Чорнетки резолюції тільки якщо задача АКТУАЛЬНА для ДАНОГО співробітника та

currentTask.mi_wfState === "ONCONSIDERATION" && currentResolution && (

<Accordion
   TransitionProps={{ unmountOnExit: true }}
   // expanded={expanded === `panel-${currentResolution.ID}`}
   // onChange={ handleChange(`panel-${currentResolution.ID}`) }
   sx={{paddingRight: 0}}
   defaultExpanded={true}
>
   <AccordionSummary
      id={`panel-${currentResolution.ID}-header`}
      aria-controls={`panel-${currentResolution.ID}-content`}
      expandIcon={<ExpandMoreIcon />}
      sx={{paddingRight: 0, flexDirection: "row-reverse"}}
   >

      <div className="left marginLeft">
         <div className='hubDescription'>
            <span>{currentResolution.mi_wfState === 'NEW' ? 'Проект' : currentResolution['mi_wfState.name']} / </span>
            <b>{currentResolution.plannedOrgUnitCaption}</b>
            <span> / {currentResolution['taskType.name']}</span>
         </div>
         <div className="">{nl2br(currentResolution.shortText)}</div>

         {currentResolution.taskType === 'PERIODICAL' ?
            <div className='hubDescription'>
               <span>Період виконання завдань: </span>
               <b>{currentResolution['periodicalType.name']}</b>
               <span> з {moment(currentResolution.periodicalBegin).format('LL')}</span>
               <span> до {moment(currentResolution.periodicalEnd).format('LL')}</span>
            </div>
         :
            <div className='hubDescription'>до { currentResolution.executionTerm && moment(currentResolution.executionTerm).format('LL') }</div>
         }

         <div className="hubDescription">
         {
            executorProp.map((ex, i) => {
               const [[key, value]] = Object.entries(ex)
               if (currentResolution.execute && currentResolution.execute.find(x => x.execRole === key))
                  return (
                     <div key={i}>
                        <span className="bold left">{value}: </span>
                        <Executors currentExecutorID={111} currentUser={currentUser} data={currentResolution.execute} role={key}></Executors>
                     </div>
                  )
               else
                  return (null)
            })
         }
         </div>
      </div>
   </AccordionSummary>

{currentTask.mi_wfState === "ONCONSIDERATION" && (
<AccordionActions>
   <FormControl fullWidth>
      <Divider />

      <Button
         size="small"
         variant="text"
         color="inherit"
         onClick={ () => {
            dataStorageContext.handleData({
               currentConsideration: {
                  docID: data.ID,
                  considerationTaskID: currentTask.ID
               }
            })
            dataStorageContext.handleData({
               targetResolution: {
                  ...currentResolution,
                  docID: data.ID
               }
            })
            swiper.slideTo(5) // ConsiderationResolutionPanel
         }}
         id={`considerationEdit-${currentTask.ID}-button`}
         sx={{textTransform: 'none', fontSize: '1em', mb: 2, mt: 2}}
      >
         Змінити
      </Button>

   </FormControl>
</AccordionActions>
)}

</Accordion>
)}

{ // Показ дерева резолюцій, що написані під цим конкретним Розглядом
   currentTask.mi_wfState !== "ONCONSIDERATION" && currentResolution && (
      getResolutionTree(data.resolution).map(item => {
         return (
             <Resolution
                 key={item.ID}
                 item={{...item, docID: data.ID}}
                 executorID={222}
                 currentUser={currentUser.staffUnitID}
                 level={1}
                 activTreeNodes={[]}
                 handleApprove={()=>{}}
             >
             </Resolution>
         )
      })
   )
}

</AccordionDetails>
      </Accordion>
   )
}

export default ConsiderationPanel
