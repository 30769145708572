import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export const WaitPanel = () => {
   return (
      <div className='hub-waiting'>
         <CircularProgress color="inherit" size={80} />
      </div>
   );
}
