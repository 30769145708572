import React from 'react';
import '../css/do.css';
// import $ from 'jquery';
import Button from './Button';
import {postApproveDoc, doPost} from './Do';
// import ButtonPrev from './ButtonPrev';
import { ErrorContext } from './ErrorProvider';
import Log from './Splunk';
import {TextField} from "@mui/material";
// import Box from '@mui/material/Box';
// import Input from '@mui/material/Input';
// import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
// import FormControl from '@mui/material/FormControl';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import { ThirtyFpsRounded } from '@mui/icons-material';

import { iit_signature, doc_resolution, doc_considerationtask } from './Resolution.lib';

class SignPanel extends React.Component {

    static contextType = ErrorContext;

    constructor(props) {
        super(props);
    }
    state = {
        waiting: false,
        username:"",
        password:"",
        showPassword: false
    }

    handleChange=(event)=>
    {
        this.setState({password: event.target.value})
    }

    handleName = (event)=>
    {
        this.setState({username: event.target.value})
    }

    handleClickShowPassword = () => {
        let n = !this.state.showPassword;
        this.setState({showPassword: n });
    }

    //--was pushed button sign
    handleSign = ()=>{
    try{
        localStorage.setItem("gryadauser",JSON.stringify({name: this.state.username}))

        let base64string = this.props.currentDoc.pdf;
        var recParticipantID = this.props.currentDoc.currentRecID;
        var comment = this.props.currentDoc.comment;
        var docID = this.props.currentDoc.ID;
        var handleDidSigned = this.props.handleDidSigned;
        const signType = this.props.currentDoc.currentStageKind;

        const {docKind, isNeedSignForResolution = false} = this.props.currentDoc
        if (isNeedSignForResolution === true && (docKind === 'EXECUTION' || docKind ==='CONSIDERATION')) {
            base64string = this.props.dataStorageContent.data.resolution4sign.CONTENT
        }

        this.setState({waiting: true});

        let requestData = {
            "username": this.state.username,
            "password": this.state.password,
            "data": base64string
            }
        let url = process.env.REACT_APP_SIGN_URL

        // window.setTimeout(()=>{
            fetch(url, {
                method:"POST",
                headers:{
                    'Content-Type': 'application/json',
                    'accept':'text/plain'
                },
                body: JSON.stringify(requestData)
            })
            .then(response => response.json())
            .then((signModel) => {
                if (signModel.errorCode === 0) {

                    // якщо підписуємо Резолюцію
                    if (isNeedSignForResolution) {
                        const url = [], queries = []

                        let query = iit_signature.saveSignature({
                            docID: docID,
                            resolutionID: this.props.dataStorageContent.data.resolution4sign.ID,
                            signType: "RESOLUTION",
                            sign: signModel.sign,
                            signerName: 'DoMobile',
                            serial: 'DoMobile'
                        })
                        queries.push(query)
                        url.push('iit_signature.saveSignature')

                        // Якщо Резолюція
                        if (docKind ==='EXECUTION') {
                            query = doc_resolution.delegate({
                                executorID: this.props.dataStorageContent.data.currentResolution.executorID
                            })
                        }
                        // Якщо НаРозгляд
                        else if (docKind === 'CONSIDERATION') {
                            query = doc_considerationtask.startExecution(this.props.dataStorageContent.data.currentConsideration.considerationTaskID)
                        }

                        queries.push(query)
                        url.push('wf_statechart.dispatchSMEvent')

                        doPost(url.join('*'), JSON.stringify(queries), '/ubql?rq=')
                            .then( () => handleDidSigned() )
                            .catch( error => this.context.onError(error) )
                            .finally(() => {
                                this.props.dataStorageContent.deleteData('resolution4sign')
                                this.props.dataStorageContent.deleteData('currentResolution')
                                this.props.dataStorageContent.deleteData('currentConsideration')
                                this.props.dataStorageContent.deleteData('targetResolution')
                            })
                    }
                    // якщо підписуємо Погодження документа
                    else {

                        var data2 = JSON.stringify({
                            method: "saveSignature",
                            docID:docID,
                            signType: signType,
                            sign: signModel.sign
                        });
                        doPost("saveSignature",data2)
                        .then(data=>{
                            postApproveDoc(recParticipantID,true, comment)
                            .then(data=>{
                                this.setState({waiting: false});
                                handleDidSigned();
                            })
                            .catch(err=>{
                                this.setState({waiting: false});
                                this.context.onError(err);
                            });
                        })
                        .catch(err=>{
                            this.setState({waiting: false});
                            throw new Error(err);
                        });

                    }

                    }else{
                        this.setState({waiting: false});
                        Log("Sign error code " + signModel.errorCode);
                        this.context.onError(`Error: ${signModel.result}` + signModel.errorCode);
                    }
                })
                .finally(() => {
                    this.setState({waiting: false});
                })
                .catch(err=>{
                    this.setState({waiting: false});
                    Log(err.message);
                    this.context.onError(err);
                });

        // },100)
    }catch(ex)
    {
        this.setState({waiting: false});
        Log(ex.message);
        this.context.onError(ex);
    }
    }

    //--convert base64 string to byte array
    base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes;
    }

    componentDidMount(){
        let gryadaUser = JSON.parse(localStorage.getItem("gryadauser"))?.name;

        ///--set default username from main login screen
        if(gryadaUser == undefined)
        {
            let user = JSON.parse(localStorage.getItem("user"))?.name;
            if(user != undefined){
                let clearUsername = user;
                if(user.indexOf('\\') !== -1)
                {
                    clearUsername = user.split('\\')[1];
                }
                localStorage.setItem("gryadauser",JSON.stringify({name: clearUsername}));
                this.setState({username: clearUsername});
            }
        }else{
            this.setState({username: gryadaUser})
        }
    }

    render() {

        return (
        <div  className="navigationPanel">
            <form>

            <TextField id="name2" margin="normal" fullWidth
            autoComplete="off"
            InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            value={this.state.username}
            onChange={this.handleName}
            label="Користувач Гряда-301" variant='outlined'/>


            <TextField id="password2" margin="normal" fullWidth
                type={this.state.showPassword  ? 'text' : 'password'}
                autoComplete="new-password"
                InputProps={{
                    endAdornment: (
                    <InputAdornment position="start">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                            >
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                    </InputAdornment>
                    ),
                }} value={this.state.password} onChange={this.handleChange} label="Пароль з Гряди-301" />


            <div className="signPanel">
                    <>
                    <Button wait={this.state.waiting} name={this.props.currentDoc.currentStageKind != "VISA" ? "Підписати": "Завізувати"} onClick={this.handleSign}></Button>
                    </>
            </div>

            </form>

        </div> );
    }
}

export default SignPanel;
