import React, {useState, useEffect} from "react"

export const DataStorageContent = React.createContext()

export const useDataStorage = () => { return React.useContext(DataStorageContent) }

export const DataStorage = ({children}) => {

   const [data, setData] = useState({})

   const handleData = newState => {
      setData(prevState => ({
         ...prevState,
         ...newState
      }))
   }

   const deleteData = newState => {
      setData(prevState => {
         delete prevState[newState]
         return prevState
      })
   }

   useEffect(() => {
      console.log("DataStorage: %O", data)
   }, [data])


   return (
      <DataStorageContent.Provider value={{data, handleData, deleteData}}>
         {children}
      </DataStorageContent.Provider>
   )
}
