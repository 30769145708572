
const log2map = (name, event) => {
   /*
      /map/api/eventlog -> https://map.ukrtransnafta.com/api/eventlog
   */
   const url = '/map/api/eventlog';
   const data = {
       Description: name,
       Name: "doMobile",
       EventType: event,
   }

   fetch( url, {
      method: 'POST',
      headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
}

export default log2map
