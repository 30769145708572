import React from 'react';
import { Navigate, Location, History, useLocation, useNavigate } from 'react-router-dom';
import Button from './Button';
import { useError, ErrorContext } from './ErrorProvider';

export const Error = () =>
{
    const navigate = useNavigate();
    const {error, onError} = useError();

    const handleReturn = ()=>{        
        navigate("/");
    }

    return(        
        <div  className="loginPage">
        <div className='loginPanel'>
            <div className='loginPanel hub-white'>
                Сталася помилка
            </div>
            <div className="hub-white">
                {error}
            </div>
            <Button name="Повернутися" onClick={()=>handleReturn()} />
        </div>
        </div>
    );   
}
export default Error;