import React from "react"
import { Button } from '@mui/material'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'

const ScrollUp = ({...props}) => {

   const {refScrollUp, scrollToRef} = props

   const [showElement, setShowElement] = React.useState(false)

   const handleVisibleButton = event => {
      const position =  event.currentTarget.scrollTop
      setShowElement(position > 400 ? true : false)
   }

   React.useEffect(() => {

      const element = refScrollUp.current
      element && element.addEventListener('scroll', handleVisibleButton)

      return () => {
         element.removeEventListener('scroll', handleVisibleButton)
      }

   }, [refScrollUp])

   return (
         <Button
            onClick={ e => scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' }) }
            size="large"
            variant="text"
            color="inherit"
            sx={{
               zIndex: 9,
               position: 'fixed',
               left: '40%',
               bottom: 30,
               opacity: 0.4,
               display: showElement ? 'block' : 'none'
            }}
         >
            <ArrowCircleUpIcon sx={{fontSize: 60}} />
         </Button>
   )

}

export default ScrollUp
