import React from "react"
import Skeleton from '@mui/material/Skeleton'
import Grid from "@mui/material/Grid"

const SkeletonList = ({...props}) => {
   const {rows} = props

   return (
      <Grid
         container
         direction="column"
         sx={{mt:3}}
      >
         {
            [...Array(rows)].map((item, key) => (

               <Grid
                  key={key}
                  item
                  justifyContent="center"
                  alignItems="flex-start"
                  sx={{mb:2}}
               >
                  <Skeleton animation="wave" variant="text" width={'90%'}></Skeleton>
                  <Skeleton animation="wave" variant="text" width={'60%'}></Skeleton>
                  <Skeleton animation="wave" variant="text" width={'30%'}></Skeleton>
                  <Skeleton animation="wave" variant="text" width={'50%'}></Skeleton>
                  <Skeleton animation="wave" variant="text" width={'80%'}></Skeleton>
               </Grid>
            ))
         }
      </Grid>
   )
}

export default SkeletonList
