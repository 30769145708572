import React from "react";
// import '../css/do.css';
import {getDocKind, getDocState} from './Do.js';

class Item extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        var envName = process.env.NODE_ENV ?? "development";
        var docTypeStyle = "hubDescription";
        var requireProcessStyle = "hubDescription";
        var executorTerm = "";

        ///---define document kind
        if (getDocKind(this.props.data) === "EXECUTION")
        {
            docTypeStyle = "execution_doc_type";
            executorTerm = this.props.data.execItemExecutionTerm ? this.props.data.execItemExecutionTerm.substring(0,10) :"";
        }
        else if (getDocKind(this.props.data) === "RECONCILIATION")
        {
            docTypeStyle = "reconcil_doc_type";
            if (this.props.data.docState === "RETURNEDFROMRECONCILIATION")
                docTypeStyle += " reconciliationRejected";
        }
        else if (getDocKind(this.props.data) === "CONSIDERATION")
        {
            docTypeStyle = "consideration_doc_type";
        }

        ///---define current document state
        if (getDocState(this.props.data) === "PROCESSED")
        {
            executorTerm = "";
        }
        else if (getDocState(this.props.data) === "NOTPROCESSED")
        {
            requireProcessStyle = "unprocessedClass hubDescription";
        }

        return (
            <div className={docTypeStyle} onClick={() => this.props.handleItemClick(this.props.data)}>
                <div className={requireProcessStyle}>
                    <img src={getDocState(this.props.data) === "NOTPROCESSED" ? "/images/unchecked.png" : "/images/checked.png"} className="hubTaskPictogram" alt="" />
                    {executorTerm ? <span className="warningText"> (виконати до {executorTerm}) </span> : ""}
                    <span> </span>
                    {this.props.data.mi_tr_shortText}
                    {this.props.data.docRegNumber ? <div>{this.props.data.docCaption}</div> : null}
                </div>
                {/* <div className="hubSubDescription">{this.props.data.docState} | {this.props.data.executorState} | {this.props.data.recParticipantState} | {this.props.data.docKind}</div> */}
                <div className="hubSubDescription"><em>{this.props.data.docStateName }</em> / {this.props.data.docKind}</div>
                <div className="hubSubDescription">
                    {envName === "development" && "docID:" + this.props.data.docID + " | "}
                    {this.props.data.createdCaption}
                </div>
            </div>
        )
    }
}
export default Item;
