import React, { Component } from 'react';
import { b64toBlob, doGet } from './Do';
import {useError} from './ErrorProvider';

export const Attachments = ({data})=> {
    var error = useError();

    const handleDownloadAttachment = (id)=>{
        doGet("getAttachment&ID="+id)
        .then(ret => {
            const blob = b64toBlob(ret.file, ret.ct);
            const blobUrl = URL.createObjectURL(blob);
            var tempLink = document.createElement('a');
            tempLink.href = blobUrl;
            tempLink.setAttribute('download', ret.origName);
            tempLink.click();
        })
        .catch(err => {
            error.onError(err);
        });
    }

    const getIconName = (filename)=>{
        var ext = filename.split('.')[filename.split('.').length -1];
        switch(ext.toLowerCase()){
            case "pdf":
                return "/images/pdficon.png";
            case "doc":
                return "/images/docicon.jpg";
            case "docx":
                return "/images/docicon.jpg";
            case "xls":
                return "/images/xlsicon.png";
            case "xlsx":
                return "/images/xlsicon.png";
            default:
                return "/images/unknownicon.png";
        }
    }

        if (data.attachDocs) {
            return ( <div className="hub-attach-panel">
                {
                    data.attachDocs.map(item => {
                        return(
                        <div key={item.ID} onClick={() => handleDownloadAttachment(item.ID)} className="hub-attach-elem hubdocKindName">
                            <img src={getIconName(item.origName)} className="attachImage" alt="" />
                            <div className="attachFile">{item.origName}</div>
                        </div>
                        )
                    })
                }
            </div> );
        }else{
            return <div></div>
        }
}

export default Attachments;
