import './App.css';
import Screen from './components/Screen';
import {Route,Routes,BrowserRouter} from 'react-router-dom';
import { AuthProvider } from './components/AuthProvider';
import {ProtectedRoute} from './components/ProtectedRoute';
import Login from './components/Login';
import { ErrorProvider } from './components/ErrorProvider';
import Error from './components/Error';
import Vpn from './components/Vpn';
import { Loader } from './components/Loader';
import { DataStorage } from "./components/DataStorage";

function App() {
  return (
    <div className="App">
        <BrowserRouter>
            <ErrorProvider>
              <Loader>
                <AuthProvider>
                  <DataStorage>
                    <Routes>
                      <Route index element={<ProtectedRoute><Screen></Screen></ProtectedRoute>} />
                      <Route path="/reconciliate/:recid" element={<ProtectedRoute><Screen></Screen></ProtectedRoute>}></Route>
                      <Route path="/execute/:execid" element={<ProtectedRoute><Screen></Screen></ProtectedRoute>}></Route>
                      <Route path="/login" element={<Login></Login>} />
                      <Route path="/error" element={<Error></Error>} />
                      <Route path="/vpn" element={<Vpn></Vpn>} />
                    </Routes>
                  </DataStorage>
                </AuthProvider>
              </Loader>
            </ErrorProvider>
        </BrowserRouter>
    </div>
  );
}

export default App;
