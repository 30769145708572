import React from "react";
import Item from "./Item";
import ScrollUp from "./ScrollUp";

class Items extends React.Component {

    constructor(props) {
        super(props);
        this.handleReachEnd = this.handleReachEnd.bind(this);
        this.myDiv = React.createRef();
        this.taskItemsRef = React.createRef();
    }

    handleReachEnd = event => {
        const element = event.currentTarget;
        if (element.offsetHeight + element.scrollTop >= element.scrollHeight - 1)
            this.props.onReachEnd();
    }

    /* componentDidMount() {
        const myDiv = document.querySelector('#scrolling')
        myDiv.addEventListener('scroll', () => {
            if (this.myDiv.offsetHeight + this.myDiv.scrollTop >= this.myDiv.scrollHeight - 10) {
                this.props.onReachEnd();
            }
        })
    } */

    componentDidMount() {
        // this.myDiv = document.querySelector('#scrolling')
        this.myDiv.current.addEventListener('scroll', this.handleReachEnd)
        // console.log("Items.componentDidMount")
    }

    componentWillUnmount () {
        this.myDiv.current.removeEventListener('scroll', this.handleReachEnd)
        // console.log("Items.componentWillUnmount")
    }

    render() {
        return (
            <div className="hub-width100" id="scrolling" ref={this.myDiv}>
                <div id="taskItems" ref={this.taskItemsRef}>
                {
                    Object.keys(this.props.items).map((key)=>{
                        return (
                            <div  key={key} >
                                <div className="hubTimeBox">{key}</div>
                                {this.props.items[key].map((m)=>{
                                    return <Item data={m} key={m.docID} handleItemClick={this.props.handleItemClick}></Item>
                                })}
                            </div>
                        )
                    })
                }
                </div>

                <ScrollUp refScrollUp={this.myDiv} scrollToRef={this.taskItemsRef}></ScrollUp>

            </div>
        )
    }
}

export default Items;
