import React from "react"
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

////
const AlertNotification = ({...params}) => {
   const {message = {}} = params

   const alertInit = React.useMemo(() => ({title: '', msg: ''}), [])

   const [alertShow, alertShowSet] = React.useState(false)

   const [alertMsg, alertMsgSet] = React.useState(alertInit)

   //
   const alertClose = React.useCallback(() => {
      alertShowSet(false)
      alertMsgSet(alertInit)
   }, [alertInit])

   //
   React.useEffect(() => {
      if (message.errMsg) {
         alertMsgSet({
            title: message.errCode !== 999 ? `Помилка #${message.errCode}` : 'Попередження',
            msg: message.errMsg.replace(/\\/g, '')
         })
         alertShowSet(true)
      }
      else
         alertClose()
   }, [message, alertClose])
   //
   React.useEffect(() => {
      if (alertShow === true) {
         const interval = setTimeout(() => {
            alertClose()
         }, 10000);
         return () => clearTimeout(interval)
      }
   }, [alertShow, alertClose])

   //
   ////
   return (
   <>
      {
         alertShow === true ?
            <Alert
               variant="standard"
               sx={{
                  textAlign: 'left',
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  zIndex: 999,
                  width: "100%",
                  // display: alertShow ? 'flex' : 'none'
               }}
               severity="error"
               onClose={() => alertClose()}
            >
               <AlertTitle>{alertMsg.title}</AlertTitle>
                  {alertMsg.msg}
            </Alert>
      : null
   }
   </>
   )
}

export default AlertNotification
