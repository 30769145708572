import React from 'react';
import {getDocData,b64toBlob,getDocKind} from './Do';
import '../css/do.css';
import Reconciliator from './Reconciliator';
import Button from './Button';
// import $ from 'jquery';
// import ButtonPrev from './ButtonPrev';
import Attachments from './Attachments';
import AddReconciliator from "./AddReconciliator";
import {TextField} from "@mui/material";
// import {UserPhoto} from "./UserPhoto";
// import { Swiper, SwiperSlide } from "swiper/react";
// import {Executors} from "./Executors";
// import moment from "moment";
// import 'moment/locale/uk';
import Resolution from './Resolution';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import { Star } from '@mui/icons-material';
import nl2br from 'react-nl2br';
import ConsiderationPanel from './ConsiderationPanel';

class DocDetail extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        enableRejectButton: false,
        expanded: "",
        docStateName: "",
        procStageName: "",
    }

    handlePanelChange = (panel) => (event, isExpanded) => {
        this.setState({expanded: isExpanded ? panel : false});
    };

    handleApprove = (event) => {
        // Отзыв резолюции
        if (event && event.target.name && /^resolution-revoke-\d{13}-button$/.test(event.target.name)) {
            this.props.onSignPage(false)
            return
        }
        // Выполнение резолюции
        else if (event && event.target.name && /^execute-\d{13}-button$/.test(event.target.name)) {
            const props = {
                comment: event.target.getAttribute('data-comment'),
                toDocument: event.target.getAttribute('data-toDocument') || null
            }
            this.props.onComment(props);
        }
        this.props.onSignPage(true);
    }

    handleReject = ()=>{
        this.props.onSignPage(false);
    }

    handleComment = (event)=>{
        this.props.onComment(event.target.value);
        if (event.target.value.length > 1) {
            this.setState({
                enableRejectButton: true
            })
        }else{
            this.setState({
                enableRejectButton: false
            })
        }
    }

    handleAddReconciliator = (reconciliator) =>
    {
        this.props.onAddReconciliator(reconciliator);
    }

    downloadMainDoc = ()=> {
        const blob = b64toBlob(this.props.data.pdf, "application/pdf");
        const blobUrl = URL.createObjectURL(blob);
        var tempLink = document.createElement('a');
        tempLink.href = blobUrl;
        tempLink.setAttribute('download', 'document.pdf');
        tempLink.click();
    }

    // Будуємо вкладене (з дітками) дерево резолюцій
    getResolutionTree(resolution) {
        if (!resolution || !Array.isArray(resolution)) return (null)

        const makeTree = (nodes, parentId) => {
            return nodes
                .filter((node) => node.parentResolutionItemID === parentId)
                .reduce(
                    (tree, node) => [
                        ...tree,
                        {
                            ...node,
                            children: makeTree(nodes, node.ID),
                        },
                    ],
                    [],
                )
            }

        // Витягуємо пункти резолюцій із Структурованої резолюції у загальний масив
        const fullItems = []
        resolution.forEach(node => {
            if ( node.resType === "STRUCTURE" && node.resolutionItem) {
                node.resolutionItem.forEach (item => {
                    item.parentResolutionItemID = node.ID
                    item.resType = 'SIMPLE'
                    item.plannedOrgUnitCaption = `Пункт №${item.itemNumber}`
                    item.signerEmail = node.signerEmail
                    fullItems.push(item)
                })
                node.execute = []
            }
            fullItems.push(node)
        })
        this.state.fullFlatResItems = fullItems
        const resTree = makeTree(fullItems, null)
        return resTree
    }

    // Масив ІД-ов резолюцій до поточного пункту виконання
    getActiveTreeNodes () {
        const myID = this.props.data.executorID
        const resolution = this.state.fullFlatResItems

        let oneFlatTree = []
        let pID = resolution.find(node => node.execute.find(node => node.ID === myID))
        oneFlatTree.push(pID)

        let parentResolutionItemID = pID.parentResolutionItemID

        let i = 0
        while (parentResolutionItemID !== null || i > 50) {
            pID = resolution.find(node => node.ID === parentResolutionItemID)
            oneFlatTree.push(pID)
            parentResolutionItemID = pID.parentResolutionItemID
            i++
        }

        oneFlatTree = oneFlatTree.map(item => item.ID).sort()
        return oneFlatTree
    }

    //
    componentDidUpdate(prevProps)
    {
        if (this.props.data.ID === prevProps.data.ID) return

        const data = this.props.data
        let stage = {}

        if (data.docKind === "RECONCILIATION")
        {
            stage = this.props.data.reconciliation?.find(x => x.recParticipantID === this.props.data.currentRecID)
            this.state.docStateName = stage?.recParticipantStateName //docStateName
            this.state.procStageName = stage?.recStageKindName
        }
        else if (data.docKind === "EXECUTION")
        {
            stage = data.resolution.map(res => res.execute.find(ex => ex.ID === data.executorID))
            stage = stage.filter(elem => elem !== undefined)[0]

            this.state.docStateName = stage && stage['mi_wfState.name']
            this.state.procStageName = stage && stage['execRole.name']
        }
        else if (data.docKind === "CONSIDERATION")
        {
            stage = data.considerationTasks.find(el => el.orgUnitID === this.props.currentUser.staffUnitID) || data.considerationTasks[0]
            this.state.docStateName = ''
            this.state.procStageName = stage.mi_wfStateName
        }
        // console.log("componentDidUpdate", stage)
    }

    //
    ////
    render() {

        const commonProps = (
            <>
                <div className="hubDocDetails">
                    {/* <div className='hubDDLeft'></div> */}
                    <div className='hubDDRight'>
                    {
                        this.props.data && this.props.data.regNumber && this.props.data.regDate ?
                            <div className='hubDescription'>
                                {this.props.data.caption}
                            </div>
                        : null
                    }
                        <div className='hubDescription'>{this.state.procStageName} {this.state.docStateName && ` / ${this.state.docStateName}`}</div>
                        <div className='hubDescription hubdocKindName'>{this.props.data && Object.keys(this.props.data).length === 0 ? "" : this.props.data.docKindName}</div>
                        <div className='hubDescription hub-main-text'>{this.props.data && Object.keys(this.props.data).length === 0 ? "" : nl2br(this.props.data.shortText)}</div>
                        <div className='hubDescription hubdocKindName'>{this.props.data && Object.keys(this.props.data).length === 0 ? "" : this.props.data.createdCaption}</div>
                    </div>
                </div>
                <div onClick={this.downloadMainDoc}>
                    <img src="/images/pdficon.png" className="roundedImage" alt="" />
                </div>
                <Attachments data={this.props.data}></Attachments>
            </>
        )

        if(this.props.data.docKind === "RECONCILIATION")
        {
            ///--draw reconciliation doc view
            return (
                <div className="hub-width100 hub-gray">
                    {commonProps}

                    {
                        Object.keys(this.props.data).length > 0
                       ? this.props.data.reconciliation.map((item)=>{
                           return <Reconciliator key={item.recParticipantID} data={item} currentRecID={this.props.data.currentRecID}></Reconciliator>
                       })
                       :""
                    }

                    {
                        this.props.data.currentRecID !== 0
                            && this.props.data.reconciliation?.find(x=>x.recParticipantID === this.props.data.currentRecID)?.recParticipantState === "ONRECONCILIATION" ?
                        (
                            <>
                                <div className='comment-panel'>
                                    <TextField
                                        fullWidth
                                        label="Коментар (обов'язковий при відхилені)"
                                        multiline
                                        rows={2}
                                        value={this.props.data.comment}
                                        onChange={this.handleComment}
                                    >

                                    </TextField>
                                </div>

                                <div className="signPanel">
                                    <Button onClick={this.handleApprove} name={Object.keys(this.props.data).length > 0 && this.props.data.currentStageKind === "VISA" ? "Завізувати" : "Підписати"}></Button>
                                    <Button disabled={!this.state.enableRejectButton} onClick={this.handleReject} name="Відхилити" ></Button>
                                    <AddReconciliator onSelect={this.handleAddReconciliator}></AddReconciliator>
                                </div>
                            </>
                        ) : null
                    }

                </div>
            );
        }
        else if(this.props.data.docKind === "EXECUTION")
        {
            ///---draw execution document view
            return (
                <div className="hub-width100 hub-gray">
                    {commonProps}

                    {
                        this.props.data.resolution ?
                        // this.props.data.resolution.map(item => {
                        this.getResolutionTree(this.props.data.resolution).map(item => {
                            return (
                                <Resolution
                                    key={item.ID}
                                    item={{...item, docID: this.props.data.ID}}
                                    executorID={this.props.data.executorID}
                                    currentUser={this.props.currentUser}
                                    level={1}
                                    activTreeNodes={this.getActiveTreeNodes()}
                                    handleApprove={this.handleApprove}
                                >
                                </Resolution>
                            )
                        })
                        : null
                    }
                </div>
            );

        }
        else if(this.props.data.docKind === "CONSIDERATION")
        {
            return (
                <div className="hub-width100 hub-gray">
                    {commonProps}
                    <ConsiderationPanel
                        data={this.props.data}
                        currentUser={this.props.currentUser}
                        reloadMainPage={this.props.reloadMainPage}
                    >
                    </ConsiderationPanel>
                </div>
            )
        }
        else {

            return (
                <div className="hub-width100 hub-gray">

                    {commonProps}

                    <Accordion disableGutters={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{flexDirection: "row-reverse"}}
                            aria-controls={`reconciliation-${this.props.data.ID}-content`}
                            id={`reconciliation-${this.props.data.ID}-header`}
                        >
                            <Typography sx={{ml:3}}>
                                Маршрут
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                        {
                            this.props.data.reconciliation && this.props.data.reconciliation.map((item) => {
                                return <Reconciliator key={item.recParticipantID} data={item} currentRecID={this.props.data.currentRecID}></Reconciliator>
                            })
                        }
                        </AccordionDetails>
                    </Accordion>


                    <Accordion disableGutters={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{flexDirection: "row-reverse"}}
                            aria-controls={`resolution-${this.props.data.ID}-content`}
                            id={`resolution-${this.props.data.ID}-header`}
                        >
                            <Typography sx={{ml:3}}>
                                Резолюції
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                        {
                            this.props.data.resolution && this.getResolutionTree(this.props.data.resolution).map(item => {
                                return (
                                    <Resolution
                                        key={item.ID}
                                        item={item}
                                        executorID={999}
                                        currentUser={this.props.currentUser}
                                        level={1}
                                        activTreeNodes={[]}
                                        handleApprove={this.handleApprove}
                                    >
                                    </Resolution>
                                )
                            })
                        }
                        </AccordionDetails>
                    </Accordion>

                </div>
            )
        }

    } // render()
}

export default DocDetail;
