import React from 'react'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import { InputLabel, TextField, Button } from '@mui/material'
import Grid from '@mui/material/Grid'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import Autocomplete from '@mui/material/Autocomplete'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import InputAdornment from '@mui/material/InputAdornment'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import SaveIcon from '@mui/icons-material/Save'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import StartIcon from '@mui/icons-material/Start'
import { useSwiper } from 'swiper/react';

import ResolutionPeriodicalPanel from './ResolutionPeriodicalPanel'
import { useDataStorage } from './DataStorage'
import { doPost } from './Do'
import ExecutorPanel from './ExecutorPanel'
import { useError } from './ErrorProvider'
import { useLoader } from './Loader'
import { convertUBQL, doc_resolution, ubm_enum, doc_resolutiontemplate, doc_document, doc_execitem, doc_controltask, doc_considerationtask } from './Resolution.lib'


const ConsiderationResolutionPanel = ({...params}) =>
{
   const {currentDoc, reloadMainPage} = params

   const swiper = useSwiper()
   const dataStorageContext = useDataStorage()
   const {onAlert} = useError()
   const loaderContext = useLoader()

   const currentConsideration = dataStorageContext.data.currentConsideration
   const currentUser = dataStorageContext.data.currentUser
   const targetResolution = dataStorageContext.data.targetResolution

   const [executionTerm, setExecutionTerm] = React.useState(moment())
   const handleExecutionTerm = (newValue) => {
      setExecutionTerm(newValue)
      /* if (moment(newValue).isAfter(executionTermParent))
         onAlert({
            responseJSON: {
               errMsg: `Термін більше дати батьківської резолюції (${executionTermParent.format("DD.MM.YYYY")})`,
               errCode: 999
            }
         }) */
   }
   // const [executionTermParent, setExecutionTermParent] = React.useState(moment())

   const [taskType, setTaskType] = React.useState('')
   const handleTaskType = event => setTaskType(event.target.value)
   const [taskTypes, setTaskTypes] = React.useState([])

   const controlTaskInit = React.useMemo(() => ([123, '< Немає даних >']), [])
   const [controlTask, setControlTask] = React.useState('')
   const handleControlTask = event => {
      const val = event.target.value
      setControlTask(val === 123 ? '' : val)
   }
   const [controlTasks, setControlTasks] = React.useState([controlTaskInit])

   const [myControl, setMyControl] = React.useState(false)
   const handleCheckboxChange = (event) => setMyControl(event.target.checked)

   const [templates, setTemplates] = React.useState([])

   const [newResolution, setNewResolution] = React.useState({})

   const [shortText, setShortText] = React.useState('')

   const [executorExist, setExecutorExist] = React.useState(false)

   const [resolutionSigner, setResolutionSigner] = React.useState(null)

   const [showPeriodicalPanel, setShowPeriodicalPanel] = React.useState(false)

   const [dataLoad, setDataLoad] = React.useState(false)

   const [periodicalType, setPeriodicalType] = React.useState('')
   const [periodicalBegin, setPeriodicalBegin] = React.useState('')
   const [periodicalEnd, setPeriodicalEnd] = React.useState('')

   const [invalid, setInvalid] = React.useState({})

   /* ///
   React.useEffect(() => {
      console.log("ResolutionPanel.currentConsideration= %O", currentConsideration)
      console.log("ResolutionPanel.currentDoc= %O", currentDoc)
   }, [currentConsideration, currentDoc]) */

   /* ///
   React.useEffect(() => {
      console.log("ResolutionPanel.newResolution= %O", newResolution)
   }, [newResolution]) */

   // taskType
   React.useEffect(() => {
      setShowPeriodicalPanel(taskType === 'PERIODICAL' ? true : false)
   }, [taskType])

   ///
   React.useEffect(() => {
      if (!targetResolution) return
      setNewResolution(targetResolution)
   }, [targetResolution])

   ///
   React.useEffect(() => {

      if (!currentConsideration || dataLoad || newResolution.ID > 3000000000000 || targetResolution.ID > 3000000000000)
         return

      const url = ['doc_resolution.addnew']
      const resolutionAddNew = doc_resolution.addnew({
         docID: currentConsideration.docID,
         parentResolutionItemID: currentConsideration.docID,
         considerationTaskID: currentConsideration.considerationTaskID
      })
      const data = [resolutionAddNew]

      setDataLoad(true)
      loaderContext.onWait()
      doPost(url.join('*'), JSON.stringify(data), '/ubql?rq=')
         .then(result => {
            const data = result[0]
            setNewResolution({ID: data.resultData.data[0][0]})
         })
         .catch(error => onAlert(error))
         .finally(() => {
            loaderContext.onResume()
            setDataLoad(false)
         })

   }, [newResolution, currentConsideration, targetResolution, onAlert, dataLoad, loaderContext])

   ///
   React.useEffect(() => {

      if (!currentConsideration || !newResolution.ID) return

      const url = []

      // taskType
      url.push('doc_resolution.getAllowedResolutionTaskType')
      const getAllowedResolutionTaskType = doc_resolution.getAllowedResolutionTaskType({
         docID: currentConsideration.docID,
      })

      url.push('ubm_enum.select')
      const ubmEnum = ubm_enum.select('DOC_TASKTYPE')

      url.push('doc_resolutiontemplate.select')
      const resolutionTemplate = doc_resolutiontemplate.select()

      // executionTerm
      url.push('doc_document.select')
      const document = doc_document.select(currentConsideration.docID)

      /* url.push('doc_execitem.select')
      const execItem = doc_execitem.select(newResolution.ID) */

      url.push('doc_controltask.select')
      const controlTask = doc_controltask.select({
         docID: currentConsideration.docID,
         taskType: ['ONDATE']
      })

      url.push('doc_resolution.getResolutionSigner')
      const getResolutionSigner = doc_resolution.getResolutionSigner({
         docID: currentConsideration.docID,
         considerationTaskID: currentConsideration.considerationTaskID,
      })

      const data = [
         getAllowedResolutionTaskType,
         getResolutionSigner,
         ubmEnum,
         resolutionTemplate,
         document,
         // execItem,
         controlTask,
      ]

      loaderContext.onWait()
      doPost(url.join('*'), JSON.stringify(data), '/ubql?rq=').then(result => {

         // шаблони
         let data = result.filter(item => item.entity === 'doc_resolutiontemplate')[0]
         setTemplates(data.resultData.data)
         newResolution.shortText ? setShortText(newResolution.shortText) : setShortText('')

         // Дата Виконання ДО & isNeedSignForResolution
         data = result.filter(item => item.entity === 'doc_document')[0]
         const document = convertUBQL(data)[0]
         currentDoc.isNeedSignForResolution = document['docKindID.isNeedSignForResolution'] ? true : false
         newResolution.executionTerm ? setExecutionTerm(newResolution.executionTerm) : setExecutionTerm(document.executionTerm /* || moment().add(1, 'd') */)
         setInvalid({executionTerm: undefined})
         // setExecutionTermParent(moment(document.executionTerm))

         // Переклад "Типів Завдання"
         data = result.filter(item => item.entity === 'ubm_enum')[0]
         const taskTypeProps = data.resultData.data

         // Повний перелік "Типів Завдання"
         data = result.filter(item => item.entity === 'doc_resolution' && item.method === 'getAllowedResolutionTaskType')[0]

         // Тип завдання + Переклад
         const taskTypes = data.result.availableTypes.map(item => {
            const find = taskTypeProps.find(node => node[3] === item)
            return {
               id: find[0],
               code: find[3],
               caption: find[1]
            }
         })

         setTaskTypes(taskTypes)
         newResolution.taskType ? setTaskType(newResolution.taskType) : setTaskType(data.result.defaultValue)

         // Завдання контролю.
         data = result.filter(item => item.entity === 'doc_controltask')[0]
         if ( data.resultData.rowCount > 0 ) {
            setControlTasks(data.resultData.data)
            setControlTask(data.resultData.data[data.resultData.data.length - 1][0])
         }
         else {
            setControlTasks([controlTaskInit])
            setControlTask('')
         }

         // Мені на контроль
         newResolution.forMyControl ? setMyControl(true) : setMyControl(false)

         // signerID
         data = result.filter(item => item.entity === 'doc_resolution' && item.method === 'getResolutionSigner')[0]
         setResolutionSigner(data.signer.signerID)

         // Periodical scheduler
         setPeriodicalType(newResolution.periodicalType || 'DAYLY')
         setPeriodicalBegin(newResolution.periodicalBegin ? moment(newResolution.periodicalBegin) : moment())
         setPeriodicalEnd(newResolution.periodicalEnd ? moment(newResolution.periodicalEnd) : moment().add(1, 'd'))

      })
      .catch(error => onAlert(error))
      .finally(() => loaderContext.onResume())

   }, [newResolution, currentConsideration, onAlert, controlTaskInit, currentUser, /* currentDoc, loaderContext */])

   //
   const saveResolution = () => {

      let url = 'doc_resolution.'
      let data = {}

      const unlock = {
         url: 'doc_resolution.unlock',
         data: doc_resolution.unlock(newResolution.ID)
      }

      if (newResolution.docID && newResolution.taskType) {
         url += 'update'
         data = {
            ID: newResolution.ID,
            forMyControl: myControl,
            taskType: taskType,
            executionTerm: executionTerm,
            shortText: shortText,
            controlTaskID: controlTask || null,
            mi_modifyDate: new Date(),
            periodicalType: periodicalType,
            periodicalBegin: periodicalBegin,
            periodicalEnd: periodicalEnd,
         }
         data = doc_resolution.update(data)

         loaderContext.onWait()
         return doPost(url, JSON.stringify([data]), '/ubql?rq=')
            .then( result => setNewResolution({...newResolution, ...convertUBQL(result[0])[0]}) )
            .then( () => doPost(unlock.url, JSON.stringify([unlock.data]), '/ubql?rq=') )
            .catch(error => {
               onAlert(error)
               throw new Error(error.responseText)
            })
            .finally(() => loaderContext.onResume())
      }
      else {
         url += 'insert'
         data = {
            ID: newResolution.ID,
            docID: currentConsideration.docID,
            considerationTaskID: currentConsideration.considerationTaskID,
            forMyControl: myControl,
            taskType: taskType,
            resType: "SIMPLE",
            mi_wfState: "NEW",
            makeDate: new Date(),
            executionTerm: executionTerm,
            signerID: resolutionSigner,
            makerID: currentUser.employeeOnStaffID,
            shortText: shortText,
            controlTaskID: controlTask || null,
            mi_owner: currentUser.userID,
            periodicalType: periodicalType,
            periodicalBegin: periodicalBegin,
            periodicalEnd: periodicalEnd,
         }
         data = doc_resolution.insert(data)

         loaderContext.onWait()
         return doPost(url, JSON.stringify([data]), '/ubql?rq=')
            .then(result => setNewResolution({...newResolution, ...convertUBQL(result[0])[0]}))
            .then( () => doPost(unlock.url, JSON.stringify([unlock.data]), '/ubql?rq=') )
            .catch(error => {
               onAlert(error)
               throw new Error(error.responseText)
            })
            .finally(() => loaderContext.onResume())
      }
   }

   //
   const deleteResolution = (ID) => {
      if (!newResolution.docID && !newResolution.taskType) return

      const query = doc_resolution.delete(ID)
      loaderContext.onWait()
      return doPost('doc_resolution.delete', JSON.stringify([query]), '/ubql?rq=')
         .then(() => {
            setNewResolution({})
            dataStorageContext.deleteData('resolution4sign')
            dataStorageContext.deleteData('currentResolution')
            dataStorageContext.deleteData('targetResolution')
            dataStorageContext.deleteData('currentConsideration')
         })
         .then(() => {
            swiper.slideTo(0) // Docdetails
            reloadMainPage()
         })
         .catch(error => onAlert(error))
         .finally(() => loaderContext.onResume())
   }

   //
   const delegateResolution = (resolution) => {
      // console.log("delegateResolution r=%O, d=%O", resolution, currentDoc)
      if (currentDoc.isNeedSignForResolution === false) {
         loaderContext.onWait()
         const query = doc_considerationtask.startExecution(currentConsideration.considerationTaskID)
         return doPost('wf_statechart.dispatchSMEvent', JSON.stringify([query]), '/ubql?rq=')
            .then(result => {
               // console.log('doc_considerationtask=%O', result)
               swiper.slideTo(0)
               reloadMainPage()
            })
            .catch(error => onAlert(error))
            .finally(() => loaderContext.onResume())
      }
      else if (currentDoc.isNeedSignForResolution === true) {
         loaderContext.onWait()
         const query = doc_resolution.getDataForSign(resolution.ID)
         return doPost('doc_resolution.getDataForSign', JSON.stringify([query]), '/ubql?rq=')
            .then(result => {
               // console.log('doc_resolution.getDataForSign=%O', result)
               dataStorageContext.handleData({resolution4sign: result[0].data})
               swiper.slideTo(2) // SignPanel
            })
            .catch(error => onAlert(error))
            .finally(() => loaderContext.onResume())
      }
   }

   //
   const checkForm = (resolution) => {
      console.log("checkForm r=%O", resolution)

      if (resolution.taskType === 'ONDATE') {
         if (moment(resolution.executionTerm).isValid() === false) {
            setInvalid({executionTerm: <React.Fragment>Заповніть <b>термін</b> виконання та <b>збережіть</b> резолюцію</React.Fragment>})
            return false
         }
         else
            setInvalid({executionTerm: undefined})
      }
      return true
   }

   /*
   ***
   */
   return (

   <div style={{
      width: '100vw',
      padding: '0 .2em',
      overflow: 'scroll',
      height: 'calc(100vh - 81px)',
   }}>

   <Grid
      container
      alignItems='center'
      justifyContent='space-between'
      alignContent='space-between'
      direction='row'
      sx={{
         textAlign: 'left',
         pl: .5,
      }}
      spacing={1.5}
   >

   <Grid item xs={12}>
      <Typography variant="subtitle1">
         Проста резолюція (На розгляд)
      </Typography>
   </Grid>


   <Grid item xs={2}>
      <FormControl>
      <Button
            aria-label='Delete Resolution'
            size="large"
            variant="text"
            color="inherit"
            sx={{minWidth:'auto', m:0}}
            onClick={ () => deleteResolution(newResolution.ID) }
            disabled={ !(newResolution.docID && newResolution.taskType) }
         >
            <DeleteForeverIcon />
         </Button>
      </FormControl>
   </Grid>

   <Grid item xs={2}>
      <FormControl>
      <Button
            aria-label='Save Resolution'
            size="large"
            variant="text"
            color="inherit"
            sx={{minWidth:'auto', m:0}}
            onClick={ () => saveResolution() }
         >
            <SaveIcon />
         </Button>
      </FormControl>
   </Grid>

   <Grid item xs={8}>
      <FormControl>
      <Button
            endIcon={<StartIcon />}
            aria-label='Delegate Resolution'
            size="large"
            variant="outlined"
            color="inherit"
            sx={{minWidth:'auto', m:0, textTransform: 'none'}}
            onClick={ () => checkForm(newResolution) && delegateResolution(newResolution) }
            disabled={ !(newResolution.docID && newResolution.taskType && shortText && executorExist) }
         >
            На виконання
         </Button>
      </FormControl>
   </Grid>

   <Grid item xs={12}>
      <FormControl fullWidth>
         <Autocomplete
            size='small'
            name="shortText"
            freeSolo
            value={shortText}
            autoSelect={true}
            onChange={(event, newValue) => {
               if (typeof newValue === 'string') {
                  setShortText(newValue)
               } else if (newValue && newValue.inputValue) {
                  setShortText(newValue.inputValue)
               } else {
                  setShortText(newValue ? newValue.label : '');
               }
            }}
            options={templates.map(([id, caption]) => ({id: id, label: caption}))}
            renderInput={(params) => <TextField {...params} label="Зміст" required />}
         />
      </FormControl>
   </Grid>

   <Grid item xs={5}>
      <FormControl component="fieldset">
         <FormControlLabel
            value="Мені на контроль"
            control={
               <Checkbox
                  name="myControl"
                  color="default"
                  checked={myControl}
                  onChange={handleCheckboxChange}
               />
            }
            label="Мені на контроль"
            labelPlacement="start"
         />
      </FormControl>
   </Grid>

      <Grid item xs={6}>
         <FormControl required>
            <InputLabel id="taskType-label">Тип завдання</InputLabel>
            <Select
               size='small'
               autoWidth
               labelId="taskType-label"
               name="taskType"
               value={taskType}
               label="Тип завдання"
               onChange={handleTaskType}
               sx={{minWidth: '9em'}}
            >
            {
               taskTypes?.map(item => (
                  <MenuItem key={item.id} value={item.code}>{item.caption}</MenuItem>
               ))
            }
            </Select>
         </FormControl>
      </Grid>

      {showPeriodicalPanel ?
      <Grid item xs={12}>
         <ResolutionPeriodicalPanel
            periodicalType={periodicalType}
            setPeriodicalType={setPeriodicalType}
            periodicalBegin={periodicalBegin}
            setPeriodicalBegin={setPeriodicalBegin}
            periodicalEnd={periodicalEnd}
            setPeriodicalEnd={setPeriodicalEnd}
         >
         </ResolutionPeriodicalPanel>
      </Grid>
      :
      <Grid item xs={6}>
         <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDatePicker
               required
               name="executionTerm"
               closeOnSelect={true}
               label="Термін"
               inputFormat="DD.MM.YYYY"
               value={executionTerm}
               onChange={handleExecutionTerm}
               renderInput={(params) => (
                  <TextField
                     {...params}
                     size='small'
                     required
                     error={!!invalid.executionTerm}
                     helperText={invalid.executionTerm}
                  />
               )}
               componentsProps={{
                  actionBar: {
                     actions: [],
                  },
               }}
               InputProps={{
                  endAdornment : (
                     <InputAdornment position="end">
                        <CalendarMonthIcon />
                     </InputAdornment>
                  )
               }}
            />
         </LocalizationProvider>
      </Grid>
      }

      <Grid item xs={11}>
         <FormControl fullWidth size="small">
            <InputLabel id="controlTask-label">Завдання контролю</InputLabel>
            <Select
               labelId="controlTask-label"
               name="controlTask"
               value={controlTask}
               label="Завдання контролю"
               onChange={handleControlTask}
            >
               {
                  controlTasks?.map(item => (
                     <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                  ))
               }
            </Select>
         </FormControl>
      </Grid>

      <Grid item xs={12}>
         <ExecutorPanel
            newResolution={newResolution}
            saveResolution={newResolution.docID && newResolution.taskType ? () => Promise.resolve() : saveResolution}
            executorExist={setExecutorExist}
            taskType={taskType}
         ></ExecutorPanel>
      </Grid>

   </Grid>

   </div>

   )
}

export default ConsiderationResolutionPanel
