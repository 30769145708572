import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { FormControl } from '@mui/material'

const AlertDialog = (props) => {

   const {open, setOpen, onConfirm} = props

   return (
      <Dialog
         open={open}
         onClose={ () => setOpen(false) }
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         align='justify'
      >
         <DialogTitle id="alert-dialog-title">
            Дія <b>«Ознайомлений»</b>
         </DialogTitle>
         <DialogContent>
            <DialogContentText id="alert-dialog-description" alignContent={'end'}>
               Ви дійсно бажаєте виконати дію: <b>Ознайомлений</b>?
               <br/>
               У разі підтвердження, завдання розгляду буде виконане, і ви не зможете накласти резолюції на документ.
            </DialogContentText>
         </DialogContent>
         <DialogActions>
            <FormControl fullWidth>

               <Button
                  onClick={() => {
                     setOpen(false)
                     onConfirm()
                  }}
                  autoFocus
                  size="large"
                  variant="text"
                  color="inherit"
               >
                  Т а к
               </Button>

               <Button
                  onClick={ () => setOpen(false) }
                  size="large"
                  variant="text"
                  color="inherit"
                  sx={{m: 2,}}
               >
                  Н і
               </Button>

            </FormControl>
         </DialogActions>
      </Dialog>
   )
}

export default AlertDialog
