import React, { Component } from 'react';
import ButtonLogout from './ButtonLogout';
import ButtonUsers from './ButtonUsers';

class TaskBar extends Component {
    constructor(props) {
        super(props);
    }
    state = {  }

    handleLogout = ()=>{
        this.props.onLogout();
    }
    render() { 
        return ( 
        <div className='hubTaskBar'>
            <ButtonLogout></ButtonLogout>
            {/* <ButtonUsers  swiperId=".mySwiper"></ButtonUsers> */}
        </div> );
    }
}
 
export default TaskBar;