import React, {useState} from 'react';
import { WaitPanel } from './WaitPanel';

export const LoaderContext = React.createContext(null);
export const useLoader = ()=>{return React.useContext(LoaderContext)};

export const Loader = ({children})=>
{
    var [count,setCount] = useState(0);

    const handleWait = ()=>{
        let n = count +1 ;
        if(count >= 0){
            setCount(n);
        }else{
            setCount(0);
        }
        
    }

    const handleResume = () =>{
        let n = count -1 ;
        if(count > 0){
            setCount(n);
        }else{
            setCount(0);
        }
    }

    var waitQueue = {
        count,
        onWait: handleWait,
        onResume: handleResume
    }

    return(
        <LoaderContext.Provider value={waitQueue}>
            {count > 0 ? (<WaitPanel></WaitPanel>) : ""}
            {children}
        </LoaderContext.Provider>
    );
}