import React from 'react';
import { readKey, writeKey } from './Do';
import {useLocation,useNavigate} from 'react-router-dom';
import { useError } from './ErrorProvider';
import Log from "./Splunk";
import CryptoJS from "crypto-js";
import $ from "jquery";
import { useLoader } from './Loader';
import log2map from './log2map.lib';

export const AuthContext = React.createContext(null);

export const useAuth = () => React.useContext(AuthContext)

export const AuthProvider = ({children}) =>
{
    const [key, setKey] = React.useState(readKey());
    const location = useLocation();
    const navigate = useNavigate();
    const {error, onError} = useError();
    var loader = useLoader();

    const crc32 = (function () {

        var table = []
        for (var i = 0; i < 256; i++) {
            var c = i
            for (var j = 0; j < 8; j++) {
                c = (c & 1) ? (0xedb88320 ^ (c >>> 1)) : (c >>> 1)
            }
            table.push(c)
        }

        return function (str, crc) {
            str = unescape(encodeURIComponent(str))
            if (!crc) crc = 0
            crc = crc ^ (-1)
            for (var i = 0; i < str.length; i++) {
                var y = (crc ^ str.charCodeAt(i)) & 0xff
                crc = (crc >>> 8) ^ table[y]
            }
            crc = crc ^ (-1)
            return crc >>> 0
        }

    })()

    const hexa8 = (value) => {
        const num = parseInt(value, 10)
        return isNaN(num) ? '00000000' : num.toString(16).padStart(8, '0')
    };

    const getSignature = (clientSessionID, sessionPrivateKey, secretWord) => {
        var
            timeStampI = Math.floor(Date.now() / 1000),
            hexaTime = hexa8(timeStampI);
            var xxx = hexa8(secretWord);
            var xx2 = hexa8(clientSessionID) + hexaTime + hexa8(crc32(sessionPrivateKey + secretWord + hexaTime));
        return  hexa8(clientSessionID) + hexaTime + hexa8(crc32(sessionPrivateKey + secretWord + hexaTime));
    }

    const login = (username, password) => {
        return new Promise((resolve, reject) => {
            var url = process.env.REACT_APP_DOURL + "/auth?AUTHTYPE=UB&userName=" + username;
            let serverNonce, pwdHash, pwdForAuth, secretWord, ret = ""
            const SHA256 = CryptoJS.SHA256
            const MD5 = CryptoJS.MD5
            const clientNonce = SHA256(new Date().toISOString().substr(0, 16)).toString()
            var errorText = ""

            $.ajax({
                url: url,
                success: function (data) {
                    // LDAP AUTH
                    const realm = data.realm
                    if (realm) {
                        Log(`Try ${username} login to domain ${realm}`, "do.js")
                        serverNonce = data.nonce
                        if (!serverNonce)
                            throw new Error('invalid LDAP auth response')
                        if (data.useSasl) {
                            pwdHash = MD5(username.split('\\')[1].toUpperCase() + ':' + realm + ':' + password)
                            pwdHash.concat(CryptoJS.enc.Utf8.parse(':' + serverNonce + ':' + clientNonce))
                            pwdForAuth = MD5(pwdHash).toString()
                            secretWord = pwdForAuth
                        }
                        else {
                            pwdForAuth = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(password))
                            secretWord = pwdForAuth
                        }
                    }
                    // Local AUTH
                    else {
                        Log(`Try ${username} login to local domain.`, "do.js")
                        serverNonce = data.result
                        if (!serverNonce)
                            throw new Error('invalid auth response')
                        pwdHash = SHA256('salt' + password).toString()
                        secretWord = pwdHash
                        pwdForAuth = SHA256('/' + serverNonce + clientNonce + username + pwdHash).toString()
                    }

                    const keyUrl = url + "&password=" + pwdForAuth + "&clientNonce=" + clientNonce

                    $.ajax({
                        url: keyUrl,
                        success: function (data) {
                            const clientSessionID = data.result.split('+')[0]
                            const sessionPrivateKey = data.result
                            const signature = getSignature(clientSessionID, sessionPrivateKey, secretWord)
                            const signatureKey = "UB " + signature
                            writeKey(signatureKey)
                            ret = signatureKey
                            resolve(ret)
                            log2map(username, 'Login')
                        },
                        error: function (err) {
                            if(err.status && err.status == 403)
                            {
                                errorText = "Неправильне імя користувача, або пароль.";
                                reject(errorText);
                            }else{
                                errorText = err.statusText;
                                reject(errorText);
                            }
                        }
                    })
                },
                error: function (err) {
                    errorText = err.statusText;
                    reject(errorText);
                }
            })
        });
    }

    const handleLogin = (user, password)=>
    {
        return new Promise((resolve,reject)=>{
            login(user,password).then((key)=>{
                writeKey(key);
                setKey(key);
                resolve(key);
            })
            .catch(err=>reject(err));
        });
    }

    const handleLogout = () =>
    {
        const signatureKey = readKey();
        $.ajax({
            url: process.env.REACT_APP_DOURL + "/logout",
            method: "POST",
            data: {},
            beforeSend: function(request) {
                request.setRequestHeader("Authorization", signatureKey);
            },
            error: function(err) {
                Log(`doPost error: ${err}`,"AuthProvider.js");
            },
            complete: function() {
                writeKey(null)
                setKey(null)
                navigate("/login")
            }
        })
    }

    const token = {
        key: key,
        onLogin: handleLogin,
        onLogout: handleLogout
    }

    return (
        <AuthContext.Provider value={token}>
            {children}
        </AuthContext.Provider>
    )
}
