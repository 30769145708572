import $ from "jquery";
import React from 'react';
import AppConfig from "../AppConfig";
import CryptoJS from "crypto-js";
import Log from "./Splunk";
import { useError } from "./ErrorProvider";
import { useAuth, AuthContext } from "./AuthProvider";


export function readKey()
{
        try{
            var o = JSON.parse(localStorage.getItem("SignatureKey")).key;
            if(o != undefined){
                return o;
            }else{
                return "";
            }
        }catch(exception){
            Log(`Exception: ${exception}`,"do.js");
            return "";
        }
}

export function writeKey(val)
{
    localStorage.setItem("SignatureKey",JSON.stringify({"key": val}));
}

///---return data by method or redirect to auth page
export function doGet(method, prefix = '/utnapi?method='){
    return new Promise((resolve,reject)=>{
        var signatureKey = readKey();
        // var ret = {};
        if(signatureKey != ""){
            $.ajax({
                // async:false,
                beforeSend: function(request) {
                    request.setRequestHeader("Authorization", signatureKey);
                },
                url: process.env.REACT_APP_DOURL + prefix + method,
                success: function(data){
                   resolve(data);
                },
                error: function(err){
                    Log(`doGet error: ${JSON.stringify(err)}`,"do.js");
                    reject(err);
                }
            });
            // return ret;
        }else{
            writeKey("");
            reject(`signatureKey is null. Authenticate session.`);
        }
    });
}

///---return data by method or redirect to auth page
export function doPost(method, postData, prefix = '/utnapi?method='){
    return new Promise((resolve,reject)=>{
        var signatureKey = readKey();
        if(signatureKey != ""){
            $.ajax({
                // async:false,
                method:"POST",
                contentType:"application/json; charset=utf-8",
                dataType:"json",
                beforeSend: function(request) {
                    request.setRequestHeader("Authorization", signatureKey);
                },
                url: process.env.REACT_APP_DOURL + prefix + method,
                data: postData,
                success: function(data){
                   resolve(data);
                },
                error: function(err){
                    Log(`doPost error: ${JSON.stringify(err)}`,"do.js");
                    reject(err);
                }
            });
        }else{
            reject(`signatureKey is null. Authenticate session.`);
        }
    });
}

//---return document data
export function getDocData(id, doctype){
    return new Promise((resolve,reject)=>{

        let url = "";
        if (doctype === "RECONCILIATION") {
            url = process.env.REACT_APP_DOURL + `/utnapi?method=getDocument&recParticipantID=${id}`;
        }
        else if (doctype === "EXECUTION") {
            url = process.env.REACT_APP_DOURL + `/utnapi?method=getDocument&executorID=${id}`;
        }
        else if (doctype === "VIEW") {
            url = process.env.REACT_APP_DOURL + `/utnapi?method=getDocument&ID=${id}`;
        }
        else if (doctype === "CONSIDERATION") {
            url = process.env.REACT_APP_DOURL + `/utnapi?method=getDocument&ID=${id}`;
        }

        const signatureKey = readKey();

        if(signatureKey != ""){
            $.ajax({
                // async:false,
                beforeSend: function(request) {
                    request.setRequestHeader("Authorization", signatureKey);
                },
                url: url,
                success: function(data){
                    if(doctype === "RECONCILIATION")
                    {
                        //---add additional information to current document
                        data.docKind = doctype;
                        data.recParticipantID = id;
                        data.currentRecID = id;
                        data.comment = '';
                        data.currentStageKind = data.reconciliation.find(x=>x.recParticipantID == id)?.recStageKind;
                        data.cryptoName = data.reconciliation.find(x=>x.recParticipantID == id)?.cryptoName;
                    }
                    else if(doctype === "EXECUTION")
                    {
                        //---add additional to execution document
                        data.docKind = doctype;
                        data.executorID = id;
                        data.isNeedSignForResolution = data.resolution[0].isNeedSignForResolution === 1 ? true : false
                    }
                    else if (doctype === 'CONSIDERATION')
                    {
                        data.docKind = doctype;
                    }

                    resolve(data);
                },
                error: function(err){
                    reject(err);
                }
            });
        }else{
            reject(`signatureKey is null. Authenticate session.`);
        }
    });
}

//---function convert base64 string to blob array
export function b64toBlob (b64Data, contentType='', sliceSize=512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

///---accept or decaline document
export function postApproveDoc(recParticipantId,approve, comment){
    var event = approve ? "approve" :"reject";
    comment = comment ?? "";
    var data = {
        "method":"dispatchDocument",
        "recParticipantID": recParticipantId,
        "event": event,
        "comment":comment
    };
    return new Promise((resolve,reject)=>{
        doPost("dispatchDocument", JSON.stringify(data))
        .then(data=>{
            resolve(data);
        })
        .catch(err=>{
            reject(err);
        });
    });
}

export function postExecResolution({executorID, comment = '', toDocument = null})
{
    const data = {
        "executorID": executorID,
        "event": "execute",
        "executionDate": new Date(),
        "comment": comment,
        "toDocument": toDocument
    }
    // console.log("postExecResolution data=%O", data);
    return new Promise((resolve, reject) => {
        doPost("executeResolution", JSON.stringify(data))
            .then(data => resolve(data))
            .catch( err => reject(err))
        }
    )
}


//---return document kind
export const getDocKind = (doc) => {
    switch(doc.docState) {

        case "CLOSED":
            if (doc.recParticipantID)
                return "RECONCILIATION";
            else if (doc.executorID)
                return "EXECUTION";
            else if (doc.considerationID)
                return "CONSIDERATION";
            else
                return 'VIEW';
        break;

        case "ONEXECUTION":
            if (doc.recParticipantID)
                return "RECONCILIATION";
            else if (doc.considerationID)
                return "CONSIDERATION";
            else
                return "EXECUTION";
            break;

        case "READYTOBEDELEGATED":
            return "EXECUTION";
            break;
        case "EXECUTE":
            return "EXECUTION";
            break;
        case "TRANSFERED_TO_DEALS":
            return "RECONCILIATION";
            break;
        case "ONRECONCILIATION":
            return "RECONCILIATION";
            break;
        case "RECONCILED":
            return "RECONCILIATION";
            break;
        case "REGISTERED":
            return "RECONCILIATION";
            break;
        case "RETURNEDFROMRECONCILIATION":
            return "RECONCILIATION";
            break;

        case "ONCONSIDERATION":
            if (doc.recParticipantID)
                return "RECONCILIATION";
            else
                return "CONSIDERATION";
            break;

        default:
            return "RECONCILIATION";
            break;
    }
}

//---return document state
export const getDocState = (doc) => {
    switch(getDocKind(doc)) {
        case "EXECUTION":
            switch(doc.executorState)
            {
                case "ONEXECUTION":
                    return "NOTPROCESSED";
                case "READYTOBEDELEGATED":
                    return "NOTPROCESSED";
                default:
                    return "PROCESSED";
            }
        case "RECONCILIATION":
            switch(doc.recParticipantState)
            {
                case "ONRECONCILIATION":
                    return "NOTPROCESSED";
                default:
                    return "PROCESSED";
            }
        case "CONSIDERATION":
            switch(doc.considerationState)
            {
                case "ONCONSIDERATION":
                    return "NOTPROCESSED";
                default:
                    return "PROCESSED";
            }
        default:
            return "PROCESSED";
    }
}
