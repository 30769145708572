import React from 'react';

// export const ButtonUnchecked = ({all, onreconciliation, onChange, statusFilter}) =>
export const ButtonUnchecked = ({...props}) =>
{
    const {docStat, onChange, statusFilter} = props;

    const handleClick = () => {
        onChange();
    }

    if(statusFilter !== 4) {
        return(
            <div onClick={handleClick} className="hub-filter-button">
                <span className="hub-selected-text">{docStat.inWork}</span> з <span>{docStat.all}</span>
            </div>
        );
    } else {
        return(
            <div onClick={handleClick} className="hub-filter-button">
                <span>{docStat.inWork}</span> з <span className="hub-selected-text">{docStat.all}</span>
            </div>
        );
    }

}
export default ButtonUnchecked;
