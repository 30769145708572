import React from 'react'

import FormControl from '@mui/material/FormControl'
import { InputLabel, TextField, Button } from '@mui/material'
import Grid from '@mui/material/Grid'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Autocomplete from '@mui/material/Autocomplete'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import CircularProgress from '@mui/material/CircularProgress'

import { doPost, doGet } from './Do'
import ExecutorsListTable from './ExecutorsListTable'
import { convertUBQL, doc_executorroleprops, ubm_enum, doc_executor } from './Resolution.lib'
import { useError } from './ErrorProvider'
import { useLoader } from './Loader'

const photoURL = process.env.REACT_APP_MAP + "/api/userphoto/"

const ExecutorPanel = ({...props}) =>
{
   const {
      newResolution,
      saveResolution,
      executorExist,
      taskType
   } = props

   const [executor, setExecutor] = React.useState('')
   const handleExecutorValue = (e, val, reason) => {
      setExecutor(val)
   }

   const [userEmail, userEmailSet] = React.useState({})
   const userEmailHandle = newState => {
      userEmailSet(prevState => {
         // console.log(newState, prevState)
         prevState[newState.id] = newState.email
         return prevState
      })
   }

   const [executors, setExecutors] = React.useState([])

   const [executorsList, setExecutorsList] = React.useState([])

   const [executorInput, setExecutorInput] = React.useState('')

   const [executorRole, setExecutorRole] = React.useState('')
   const handleExecutorRole = event => setExecutorRole(event.target.value)

   const [executorRoles, setExecutorRoles] = React.useState([])
   const [executorRolesFull, setExecutorRolesFull] = React.useState([])

   const {onAlert} = useError()
   const loaderContext = useLoader()

   const [loading, setLoading] = React.useState(false)

   //
   React.useEffect(() => {

      if(executorInput.length < 3 || executorInput.length > 20) {
         setExecutors([])
         setLoading(false)
         return
      }

      setLoading(true)
      const timeout = setTimeout(() => {
         doGet(`getStaffUnit&q=${executorInput}`)
            .then(data => {
               const options = data.map(item => {
                  return {
                     id: item.ID,
                     email: item.email,
                     label: item.caption.substring(0, item.caption.indexOf(' (')),
                  }
               })
               setExecutors(options)
            })
            .catch(error => {
               console.error(error)
               onAlert(error)
            })
            .finally(() => setLoading(false))
      }, 600)

      return () => clearTimeout(timeout)

   }, [executorInput, onAlert])

   //
   React.useEffect(() => {
      if (newResolution && newResolution.ID)
            getExecutorsByExecItem()
         else
            setExecutorsList([])
   }, [newResolution])

   // Init roles
   React.useEffect(() => {

      const url = [], data = []

      url.push('doc_executorroleprops.select')
      data.push(doc_executorroleprops.select())

      url.push('ubm_enum.select')
      data.push(ubm_enum.select('DOC_EXECUTORROLE'))

      doPost(url.join('*'), JSON.stringify(data), '/ubql?rq=')
         .then(result => {
            // Переклад "Ролей Виконавця"
            let data = result.filter(item => item.entity === 'ubm_enum')[0]
            const executorRoleProps = data.resultData.data

            // Повний перелік "Ролей Виконавця"
            data = result.filter(item => item.entity === 'doc_executorroleprops')[0]
            // "Ролі Виконавця" + Переклад
            const executorRoles = data.resultData.data.map(item => {
               const find = executorRoleProps.find(node => node[3] === item[1])
               return {
                  id: find[0],
                  code: find[3],
                  caption: find[1]
               }
            })
            setExecutorRoles(executorRoles)
            setExecutorRole(executorRoles[0].code)
            setExecutorRolesFull(executorRoles)
      })
   }, [])

   // щоб активувати/деактивувати кнопку "Передати на виконання"
   React.useEffect(() => {
      executorsList.length ? executorExist(true) : executorExist(false)
   }, [executorsList])

   ////
   // 1) Щоб був тільки ОДИН Головний виконавець
   // 2) Якщо завдання "Інформаційне", то можлива тільки роль INFO
   React.useEffect(() => {

      if (taskType === 'FORINFORMATION') {
         setExecutorRoles(executorRolesFull.filter(el => el.code === 'INFO'))
         setExecutorRole('INFO')
      }
      else if (executorsList.some(el => el.execRole === 'MAIN')) {
         if (executorRole === 'MAIN')
            setExecutorRole('COEXECUTOR')

         setExecutorRoles(executorRolesFull.filter(el => el.code !== 'MAIN'))
      }
      else
         setExecutorRoles(executorRolesFull)

   }, [executorsList, taskType] )

   //
   //// Functions
   const getExecutorsByExecItem = () => {
      const url = 'doc_executor.getExecutorsByExecItem'
      const executors = doc_executor.getExecutorsByExecItem(newResolution.ID)
      loaderContext.onWait()
      doPost(url, JSON.stringify([executors]), '/ubql?rq=')
         .then(result => {
            const json = convertUBQL(result[0])
            setExecutorsList(json)
         })
         .catch(error => {
            console.error(error)
            onAlert(error)
         })
         .finally(() => loaderContext.onResume())
   }

   const deleteExecutor = (ID) => {
      const url = 'doc_executor.delete'
      const executor = doc_executor.delete(ID)
      loaderContext.onWait()
      doPost(url, JSON.stringify([executor]), '/ubql?rq=')
         .then(result => {
            getExecutorsByExecItem()
         })
         .catch(error => {
            console.error(error)
            onAlert(error)
         })
         .finally(() => loaderContext.onResume())
   }

   const addExecutor = () => {

      saveResolution().then(() => {
         const url = 'doc_executor.addnew'
         const data = [doc_executor.addnew()]
         loaderContext.onWait()
         doPost(url, JSON.stringify(data), '/ubql?rq=')
            .then(result => {
               result = convertUBQL(result[0])[0]
               const url = 'doc_executor.insert'
               const data = [doc_executor.insert({
                  ID: result.ID,
                  execRole: executorRole,
                  orgUnitID: executor.id,
                  // respForExecItem: true,
                  execItemID: newResolution.ID
               })]
               userEmailHandle(executor)

               return doPost(url, JSON.stringify(data), '/ubql?rq=')
                  .then(result => {
                     setExecutor('')
                     getExecutorsByExecItem()
                  })
            })
            .catch(error => {
               console.error(error)
               onAlert(error)
            })
            .finally(() => loaderContext.onResume())
      })
   }


   return (

   <Grid
      container
      alignItems='center'
      justifyContent='space-between'
      alignContent='space-around'
      direction='row'
      spacing={1.5}
   >

   <Grid item xs={12}>
      <FormControl fullWidth>
         <Autocomplete
            size="small"
            name="executor"
            noOptionsText="< Немає даних >"
            options={executors}
            value={executor}
            onChange={handleExecutorValue}
            inputValue={executorInput}
            onInputChange={(event, newInputValue) => setExecutorInput(newInputValue)}

            renderInput={(params) => (
               <TextField
                  {...params}
                  label="Виконавець"
                  size="small"
                  required
                  InputProps={{
                     ...params.InputProps,
                     endAdornment: (
                        <React.Fragment>
                           {loading && <CircularProgress color="info" size={20} />}
                           {params.InputProps.endAdornment}
                        </React.Fragment>
                     )
                  }}
               />
            )}
            renderOption={(props, option) => (
               <Box
                  {...props}
                  component="li"
                  size="small"
               >
                  <Avatar
                     sx={{
                        height: 56,
                        width: 56,
                        mr: 1,
                        ml:-1,
                        borderWidth: 1,
                        borderColor: '#cfcdcd',
                        borderStyle:'solid'
                     }}
                     alt={option.label}
                     src={option.email ? photoURL + option.email : null}
                  >
                     {option.label.substring(0, 3)}
                  </Avatar>
                  <span>
                     {option.label}
                  </span>
               </Box>
            )}
         ></Autocomplete>
      </FormControl>
   </Grid>

      <Grid item xs={6}>
         <FormControl fullWidth>
            <InputLabel
               id="executorRole-label" required>Роль</InputLabel>
            <Select
               size='small'
               autoWidth
               labelId="executorRole-label"
               name="executorRole"
               value={executorRole}
               label="Роль"
               onChange={handleExecutorRole}
            >
               {
                  executorRoles?.map(item => (
                     <MenuItem
                        key={item.id}
                        value={item.code}
                     >
                        {item.caption}
                     </MenuItem>
                  ))
               }
            </Select>
         </FormControl>
      </Grid>

      <Grid item xs={'auto'}>
         <FormControl>
         <Button
            size="large"
            variant="outlined"
            color="inherit"
            sx={{minWidth:'auto', m:0}}
            onClick={ () => addExecutor() }
            disabled={!executor ? true : false}
         >
            <ArrowDownwardIcon />
         </Button>


         </FormControl>
      </Grid>

      <Grid item xs={12}>
         <ExecutorsListTable
            executorsList={executorsList}
            executorRoles={executorRolesFull}
            executorsEmail={userEmail}
            deleteExecutor={deleteExecutor}
            getExecutorsByExecItem={getExecutorsByExecItem}
         ></ExecutorsListTable>
      </Grid>


   </Grid>

   )
}

export default ExecutorPanel
