import React, {useRef, useEffect} from 'react';
// import {UserPhoto} from "./UserPhoto";
import {Executors} from './Executors';
import moment from 'moment';
import 'moment/locale/uk'
import nl2br from 'react-nl2br';
import ExecSteps from './ExecSteps';

import { Accordion, AccordionDetails, AccordionSummary, AccordionActions, Grid } from '@mui/material'
import { Button, TextField, FormControl, Divider } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SearchIcon from '@mui/icons-material/Search'
import InputAdornment from '@mui/material/InputAdornment';
import CloseOutlined from '@mui/icons-material/CloseOutlined';
import ExecutorAvatar from './ExecutorAvatar';

import { useSwiper } from 'swiper/react';
import { useDataStorage } from './DataStorage';
import { doc_executor, doc_resolution } from './Resolution.lib';
import { doPost } from './Do'
import { useError } from './ErrorProvider'
import { useLoader } from './Loader';

const Resolution = ({...params}) =>
{
   const {
      item,
      executorID,
      currentUser,
      level = 0,
      activTreeNodes = [],
      handleApprove
   } = params

   const dataStorageContext = useDataStorage()

   const [expanded, setExpanded] = React.useState(activTreeNodes.includes(item.ID) ? `panel-${item.ID}` : false);

   const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
   };
   const [currentExecItem] = React.useState(
      item.execute && item.execute.some(item => item.ID === executorID)
   )
   const [currentExecItemNotExecuted, setCurrentExecItemNotExecuted] = React.useState(
      item.mi_wfState !== 'CLOSED'
      && item.execute.some(node => node.ID === executorID
         && ['READYTOBEDELEGATED', 'ONEXECUTION'].includes(node.mi_wfState)
         /* && node.mi_wfState === 'ONEXECUTION' */
      )
   )

   const [panelStyle, setPanelStyle] = React.useState({})

   const [executeComment, setExecuteComment] = React.useState('')

   const [toDocumetValue, setToDocumetValue] = React.useState('')
   const handleToDocumentvalue = el => {
      setToDocumetValue(el.docCaption)
      document.getElementById(`execute-${item.ID}-button`).setAttribute('data-toDocument', el.docID)
      if (!el.docID)
         dataStorageContext.handleData({foundDoc: {}})
   }

   const handleShowSearch = () => {
      dataStorageContext.handleData({
         showDoc: 'notDraft',
         target: 'Resolution'
      })
      swiper.slideTo(3) // to SearchPanel
   }

   //
   const {onAlert} = useError()
   const loaderContext = useLoader()

   const revokeResolution = (event, ID, comment) => {
      let request = {
         resolutionItemID: ID,
         comment: comment
      }
      request = [doc_resolution.revoke(request)]

      loaderContext.onWait()
      doPost('wf_statechart.dispatchSMEvent', JSON.stringify(request), '/ubql?rq=')
         .then(() => handleApprove(event))
         .catch(error => onAlert(error))
         .finally(() => setTimeout(() => loaderContext.onResume(), 500) )
   }

   const [showRevokeButton, showRevokeButtonSet] = React.useState(false)

   const accordionRef = useRef()

   const swiper = useSwiper()

   //
   useEffect(() => {
      if (currentExecItem === true && accordionRef.current) {
         setTimeout(() => {
            // console.log("accordionRef.current", accordionRef.current)
            accordionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
         }, 400)
         setPanelStyle({/* border: 'dashed 1px blue', backgroundColor: '#f8f8ff' */})

         if (dataStorageContext.data.foundDoc && dataStorageContext.data.foundDoc.docID) {
            handleToDocumentvalue(dataStorageContext.data.foundDoc)
         }
      }
   }, [currentExecItem, accordionRef, dataStorageContext.data.foundDoc])

   //
   useEffect(() => {
      if (item.mi_wfState === 'CLOSED'||
         item.execute.some(node => node.ID === executorID
            && !['READYTOBEDELEGATED', 'ONEXECUTION'].includes(node.mi_wfState)
            // && node.mi_wfState !== 'ONEXECUTION'
         )
      ) {
         setPanelStyle({/* backgroundColor: '#f4fbf4' */})
         setCurrentExecItemNotExecuted(false)
      }
   }, [item, executorID])

   //!!!//
   /* useEffect(() => {
      const handleClick = event => {
         console.log('Button clicked item=%O, activTreeNodes=%O', item, activTreeNodes);
      }
      // activTreeNodes.includes(item.ID)
      if (item.mi_wfState === 'NEW' && accordionRef.current) {
         const element = accordionRef.current
         element.addEventListener('click', handleClick)

         return () => element.removeEventListener('click', handleClick)
      }
   }, [accordionRef, item]) */

   // Розкриваємо акордіон з ПРОЕКТОМ резолюції та ВІДІЗВАНОЮ резолюцією
   useEffect(() => {
      if (['NEW', 'REVOKED'].includes(item.mi_wfState) && accordionRef.current)
         setExpanded(`panel-${item.ID}`)
   }, [accordionRef, item])

   // Чи можно Резолюцію Відкликати?
   useEffect(() => {
      if (true === showRevokeButton) return
      if (currentExecItem === true
         && currentExecItemNotExecuted === true
         && accordionRef.current
         && false === item.execute.some(node => node.mi_wfState !== 'ONEXECUTION')
      ) {
         const request = [doc_executor.getInfoExecItem(item.ID)]
         // loaderContext.onWait()
         doPost('doc_executor.getInfoExecItem', JSON.stringify(request), '/ubql?rq=')
            .then(result => {
               const data = JSON.parse(result[0].info) || {}
               const allowRevoke = data.allowRevoke || false
               showRevokeButtonSet(allowRevoke)
            })
            .catch(error => console.error(error))
            // .finally(() => loaderContext.onResume())
      }
   }, [item, currentExecItem, currentExecItemNotExecuted, accordionRef, showRevokeButton])

   //
   const executorProp = React.useMemo(() => {
      return [
         {"MAIN" : 'Головні'},
         {"COEXECUTOR" : 'Співвиконавці'},
         {"INFO" : 'До відома'},
         {"CONTROLLER" : 'Контролер'},
      ]
   }, [])

   return (
   <Accordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={ expanded === `panel-${item.ID}` }
      onChange={ handleChange(`panel-${item.ID}`) }
      sx={{paddingRight: 0}}
      style={panelStyle}
   >
      <AccordionSummary
         ref={accordionRef}
         id={`panel-${item.ID}-header`}
         aria-controls={`panel-${item.ID}-content`}
         expandIcon={<ExpandMoreIcon />}
         sx={{paddingRight: 0, flexDirection: "row-reverse"}}
      >

   <div key={item.ID} className="resolutionPanel">
      <div className="flexRow">
         {/* <UserPhoto email={item.signerEmail}/> */}
         <ExecutorAvatar
            email={item.signerEmail}
            caption={item.plannedOrgUnitCaption}
            style={{
               height: '60px',
               width: '60px',
               mr: 1,
               ml: -1,
               mb: .5,
            }}
         ></ExecutorAvatar>

         <div className="flexColumn">
            <div>({item['mi_wfState.name']})</div>
            <span className="italic bold">
               {/* {item.mi_wfState === 'NEW' ? '(ПРОЕКТ) ' : null} */}
               {item.plannedOrgUnitCaption}
               <span> від </span>
               {moment(item.mi_createDate).format('LL')}
            </span>
            {/* {
               item.executionTerm
               ?
               <span className='flexRow'><span className="marginLeft"> ( до<span className="marginLeft">{moment(item.executionTerm).format('LL')} </span>)</span></span>
               : ""
            } */}
         </div>
      </div>
      <div className="left marginLeft">
         <div className="">{nl2br(item.shortText)}</div>
         {item.executionTerm && <div className='flexRow'>до {moment(item.executionTerm).format('LL')}</div>}
         {
            executorProp.map((ex, i) => {
               const [[key, value]] = Object.entries(ex)
               if (item.execute && item.execute.find(x => x.execRole === key))
                  return (
                     <div key={i}>
                        <span className="bold left">{value}: </span>
                        <Executors currentExecutorID={executorID} currentUser={currentUser} data={item.execute} role={key}></Executors>
                     </div>
                  )
               else
                  return (null)
            })
         }
      </div>
   </div>
   </AccordionSummary>

   <AccordionDetails sx={{/* border: 'dashed 1px magenta', */ paddingRight: '3px', paddingLeft: 1.5}}>

   <ExecSteps data={item.execute}></ExecSteps>

   {item.children.map(node => (
      <Resolution
         key={node.ID}
         item={{...node, docID: item.docID}}
         executorID={executorID}
         currentUser={currentUser}
         level={level + 1}
         activTreeNodes={activTreeNodes}
         handleApprove={handleApprove}
      ></Resolution>
   ))}
   </AccordionDetails>

   {/* <div>{item.mi_wfState} !! {currentExecItem ? '1' : '0'} !! {currentExecItemNotExecuted  ? '1' : '0'}</div> */}

   {currentExecItem === true && currentExecItemNotExecuted === true ?
      <AccordionActions>
         <FormControl fullWidth>
            <Divider variant="fullWidth" light sx={{mb: 0}}/>

         <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
         >
            <Grid item xs={toDocumetValue ? 11 : 12}>
               <TextField
                  id={`execute-${item.ID}-toDocument`}
                  label="До документа"
                  size='small'
                  sx={{mb: 1}}
                  variant="outlined"
                  InputProps={{
                     readOnly: true,
                     endAdornment: (
                        <InputAdornment position="end">
                           { !toDocumetValue ? <SearchIcon /> : null }
                        </InputAdornment>
                     )
                  }}
                  onClick={() => {
                     handleShowSearch()
                  }}
                  value={toDocumetValue}
                  fullWidth
               ></TextField>
            </Grid>
            <Grid item xs={toDocumetValue ? 1 : 0}>
            {
               toDocumetValue ?
               <Button
                  size="large"
                  variant="text"
                  color="inherit"
                  sx={{p:0, minWidth:'auto'}}
                  onClick={e => handleToDocumentvalue({
                     docCaption: '',
                     docID: null
                  })}
               >
                  <CloseOutlined />
               </Button>
               : null
            }
            </Grid>
         </Grid>

            <TextField
               id={`execute-${item.ID}-comment`}
               name={`execute-${item.ID}-comment`}
               multiline
               label="Коментар (обов'язковий)"
               required
               size='small'
               sx={{mb: 1}}
               onChange={ e => {
                  const value = e.target.value
                  setExecuteComment(value)
                  document.getElementById(`execute-${item.ID}-button`).setAttribute('data-comment', value)
               } }
               value={executeComment}
            ></TextField>

            <Button
               size="small"
               variant="text"
               color="inherit"
               onClick={ e => {
                  dataStorageContext.handleData({foundDoc: {}})
                  handleApprove(e)
               }}
               id={`execute-${item.ID}-button`}
               name={`execute-${item.ID}-button`}
               sx={{textTransform: 'none', fontSize: '1em'}}
               disabled={!executeComment}
               value={item.ID}
            >
               Виконати
            </Button>

            <Button
               size="small"
               variant="text"
               color="inherit"
               onClick={ e => {
                  dataStorageContext.handleData({
                     currentResolution: {
                        ...item,
                        executorID: executorID // ІД для запуску на виконання резолюції
                     }
                  })
                  // dataStorageContext.deleteData('targetResolution')
                  dataStorageContext.handleData({
                     targetResolution: {}
                  })

                  swiper.slideTo(4) // ResolutionPanel
               }}
               id={`resolution-add-${item.ID}-button`}
               name={`resolution-add-${item.ID}-button`}
               sx={{textTransform: 'none', fontSize: '1em'}}
               data-item={JSON.stringify(item)} // 4 ResolutionPanel
            >
               Проста резолюція
            </Button>

            { showRevokeButton === true &&
               <Button
                  size="small"
                  variant="text"
                  color="inherit"
                  onClick={ e => revokeResolution(e, item.ID, executeComment)}
                  id={`resolution-revoke-${item.ID}-button`}
                  name={`resolution-revoke-${item.ID}-button`}
                  sx={{textTransform: 'none', fontSize: '1em'}}
                  disabled={!executeComment}
                  value={item.ID}
               >
                  Відкликати
               </Button>
            }

         </FormControl>
      </AccordionActions>
   : null}

   { // ПРОЕКТ резолюції
      ['NEW', 'REVOKED'].includes(item.mi_wfState) && accordionRef.current ?

      <AccordionActions>
         <FormControl fullWidth>
            <Button
               size="small"
               variant="text"
               color="inherit"
               onClick={ e => {
                  dataStorageContext.handleData({
                     targetResolution: {
                        ...item,
                        executorID: executorID // ІД для запуску на виконання резолюції
                     }
                  })
                  // 4 ResolutionPanel
                  const dataItem = JSON.parse(document.getElementById(`resolution-add-${item.parentResolutionItemID}-button`).getAttribute('data-item')) || {}
                  dataStorageContext.handleData({
                     currentResolution: {
                        ...dataItem,
                        executorID: executorID // ІД для запуску на виконання резолюції
                     }
                  })
                  swiper.slideTo(4) // ResolutionPanel
               }}
               id={`resolution-edit-${item.ID}-button`}
               name={`resolution-edit-${item.ID}-button`}
               sx={{textTransform: 'none', fontSize: '18px'}}
               value={item.ID}
            >
               Змінити
            </Button>
         </FormControl>
      </AccordionActions>

      : null
   }

   </Accordion>
   )
}

export default Resolution
