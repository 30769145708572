import React, { useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Items from "./Items";
import DocDetail from "./DocDetail";
import SignPanel from "./SignPanel";
import Login from "./Login";
import {useLoader} from "./Loader";
import {readKey, getDocData, getDocKind, doGet, postApproveDoc, writeKey, doPost, postExecResolution} from "./Do.js";
// import {useParams} from 'react-router';

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "./styles.css";
import "../css/do.css"
// import {Log} from "./Splunk";

// import required modules
// import { Navigation } from "swiper";
import TaskBar from "./TaskBar";
// import {AuthContext, AuthProvider} from "./AuthProvider";
// import {ProtectedRoute} from "./ProtectedRoute";
// import {Route,Routes, BrowserRouter} from 'react-router-dom';
import {useError} from './ErrorProvider';
// import { UserPanel } from "./UserPanel";
import Greeting from "./Greeting.jsx";
// import tripledes from "crypto-js/tripledes";

import SearchPanel from './SearchPanel.jsx';
import {useDataStorage } from "./DataStorage";
import ResolutionPanel from "./ResolutionPanel";
import ConsiderationResolutionPanel from "./ConsiderationResolutionPanel";
import FilterDocumentsPanel from "./FilterDocumentsPanel";

export const Screen = () => {

  const [authKey, setAuthKey] = useState(readKey());
  const [items, setItems] = useState([]);
  const [currentDoc,setCurrentDoc] = useState({});
  const [statusFilter,setStatusFilter] = useState(1);
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [initialized,setInitialized] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [docStat, setDocStat] = useState({});
  const [dataLoad, dataLoadSet] = useState(false)
  // const params = useParams();
  const errorContext = useError();
  const loaderContext = useLoader();
  const dataStorageContent = useDataStorage()

  ////
  const [filterDocument, filterDocumentSet] = React.useState('showAll')
  const [getMethod, getMethodSet] = React.useState('getMyDocs')
  React.useEffect(() => {
    switch (filterDocument) {
      case 'showConsider':
        getMethodSet('getMyConsDocs')
        break;
      case 'showExecution':
        getMethodSet('getMyExecDocs')
        break;
      case 'showReconcil':
        getMethodSet('getMyRecDocs')
        break;
      case 'showInfo':
        getMethodSet('getMyExecDocs&execRole=INFO')
        break;
      default:
        getMethodSet('getMyDocs')
        break;
    }
    // console.log("filterDocument=%s", filterDocument)
  }, [filterDocument, getMethodSet])
    //\\//


  const handleItemClick = (currentDoc) => {

    // @todo !! костыли и велосипеды !!
    let docKind = getDocKind(currentDoc);
    if (dataStorageContent.data.target === 'docDetail' && dataStorageContent.data.slide.active === 3)
      docKind = 'VIEW'
    // console.log("getDocKind", docKind, currentDoc)

    if(docKind === "RECONCILIATION")
      loadCurrentDoc(currentDoc.recParticipantID, docKind);
    else if(docKind === "EXECUTION")
      loadCurrentDoc(currentDoc.executorID, docKind);
    else if(docKind === "CONSIDERATION")
      loadCurrentDoc(currentDoc.docID/* considerationID */, docKind);
    else if(docKind === "VIEW")
      loadCurrentDoc(currentDoc.docID, docKind);
    else
      return
  }

  ///---load doc to state by it reconciliationId or executionId
  const loadCurrentDoc = (id,doctype) => {
    loaderContext.onWait();
    getDocData(id, doctype)
      .then(docData => {
        setCurrentDoc(docData);
        handlePageChange(1);
      })
      .catch(err => errorContext.onAlert(err))
      .finally(() => setTimeout(() => loaderContext.onResume(), 500));
  }

  ///---handle page sign process
  const handleSignPage = (approve)=> {

    try {
      const swiper = document.querySelector('.mySwiper').swiper;
      currentDoc.approve = approve;
      setCurrentDoc(currentDoc);

      let pageChange = handlePageChange;

      //Узгодження документа
      if (currentDoc.docKind === 'RECONCILIATION') {
        ///--check if action is required SIGN
        if(currentDoc.cryptoName == null || !approve)
        {
            postApproveDoc(currentDoc.currentRecID,approve,currentDoc.comment)
            .then(data=>{
              updateItems();
              pageChange(0);
              swiper.slideTo(0);
            })
            .catch(err=>{throw new Error(err)});

        }else{
          swiper.slideTo(2);
          setCurrentPage(2);
        }
      }
      // Виконання резолюції
      else if (currentDoc.docKind === 'EXECUTION') {
        // Execute
        if (approve === true) {
          loaderContext.onWait()
          postExecResolution({
            executorID: currentDoc.executorID,
            entity: currentDoc.entity,
            comment: currentDoc.comment.comment,
            toDocument: isNaN(currentDoc.comment.toDocument) ? null : currentDoc.comment.toDocument * 1
          })
          .then( data => {
            // console.log("handleSignPage.postExecResolution data=%O", data);
            dataStorageContent.deleteData('resolution4sign')
            dataStorageContent.deleteData('currentResolution')
            setCurrentDoc({})
            updateItems()
            pageChange(0)
            swiper.slideTo(0)
          })
          .catch(error => errorContext.onAlert(error))
          .finally(() => setTimeout(() => loaderContext.onResume(), 500))
        }
        // Revoke
        else if (approve === false) {
          setCurrentDoc({})
          updateItems()
          pageChange(0)
          swiper.slideTo(0)
        }
      }

    } catch (exception) {
      errorContext.onError(exception);
    }
  }

  ///---comment was added to document
  const handleComment = (comment) => {
    currentDoc.comment = comment;
    setCurrentDoc(currentDoc);
  }

  ///---goto main page and update items
  const handleMainPage = ()=>{
    handlePageChange(0);
    updateItems();
  }

  const handleAuthenticate = () => {
    setAuthKey(readKey());
    handleMainPage();
  }

  const handleLogout = ()=>{
    writeKey('');
    setAuthKey('');
  }

  //---return grouped items
  const groupItems = (items) => {
    const sorted = items.map((x)=>{
      x.docCreateDate = x.dateSort.substring(0,10);
      return x;
    }).sort(function(x){
      return x.docCreateDate;
    });

    const grouped = sorted.reduce((group, recParticipant) => {
      const { docCreateDate } = recParticipant;
      group[docCreateDate] = group[docCreateDate] ?? [];

      const index = group[docCreateDate].findIndex(x => x.docID === recParticipant.docID);
      if(index > -1)
      {
        if(recParticipant.recParticipantState === "ONRECONCILIATION")
        {
          group[docCreateDate][index] = recParticipant;
        }
      }
      else
      {
        group[docCreateDate].push(recParticipant);
      }
      return group;
    }, {});

    return grouped;
  }


  ///---load new items after refresh page
  const updateItems = ()=>{
    setPage(0);
    setItems([]);
    updateFilterButton();
    setInitialized(false);
  }

  ///---add items to existing collection (lazy load)
  const addItems = (pageNumber) => {

    if (dataLoad === true) return

    const itemsPageSize = process.env.REACT_APP_PAGE_SIZE;
    //--global all items. Lazy load data.
    const startRec = pageNumber * itemsPageSize; //process.env.REACT_APP_PAGE_SIZE;

    const getMethodUrl = `${getMethod}&limit=${itemsPageSize}&start=${startRec}&status=${statusFilter}`;

    dataLoadSet(true);
    loaderContext.onWait();
    doGet(getMethodUrl)
      .then((recParticipants => {

        if (!recParticipants.length) return;

        const grouped = groupItems(recParticipants);
        let allItems = items;
        Object.keys(grouped).forEach(key=>{
          allItems[key] = grouped[key];
        });
        // let lastPage = page + 1;
        setItems(allItems);
        setPage(page + 1);
        // loaderContext.onResume();
      }))
      .catch(error=>{
        // loaderContext.onResume();
        errorContext.onError(error);
      })
      // .finally(() => setTimeout(() => loaderContext.onResume(), 1000));
      .finally(() => {
        setTimeout(() => {
          loaderContext.onResume();
          dataLoadSet(false);
        }, 900);
      });

  }

  ///---display items with filter value
  const handleChangeStatusFilter = () =>
  {
    if(statusFilter === 4)
    {
      setStatusFilter(1);
      setPage(4);
      updateItems();
    }else{
      setStatusFilter(4);
      setPage(0);
      updateItems();
    }
  }

  ///----goto page number
  const handlePageChange = (page)=>
  {
    setCurrentPage(page);
    const swiper = document.querySelector('.mySwiper').swiper;
    swiper.slideTo(page);
  }

 ///---event fires when scroll end reached
  const handleReachEnd = () =>
  {
    addItems(page);
  }

  ///---add reconciliator to current document
  const handleAddReconciliator = (staffUnitID) =>
  {
    ///---TODO add with fetch reconciliator
    var data = {
      method: "addNewVisier",
      recParticipantID: currentDoc.recParticipantID,
      staffUnitID:staffUnitID,
      executionDate:null,
      comment:""
    };
    doPost("addNewVizier",JSON.stringify(data))
    .then(()=>{
      loadCurrentDoc(currentDoc.recParticipantID,"RECONCILIATION");
    })
    .catch((err)=>{
      errorContext.onError(err.statusText);
    });

  }

  ///---update display button showing processed|unprocessed documents
  const updateFilterButton = () => {
      //---read status processed/all documents
      doGet("getRecStat").then(data => setDocStat({...data}));
  }

  ///---define current user
  const handleWhoAmI = (user) => setCurrentUser(user);

  /// ---reload items on page 0
  const reloadMainPage = () => {
    setCurrentDoc({})
    updateItems()
    handlePageChange(0)
  }

  useEffect(()=>{
    if(!initialized)
    {
      ///---check if has parameters for document reconciliation
      /* if(params.recid)
      {
        loadCurrentDoc(params.recid,"RECONCILIATION");
      }

      ///---check if has parameters for document execution
      if(params.execid)
      {
        loadCurrentDoc(params.execid,"EXECUTION");
      } */

      // Log("Component did mount.","screen");
      updateItems();
      addItems(page);
      setInitialized(true);
    }
    else {
      if (process.env.NODE_ENV === "development") {
        const interval = setInterval(() => {
          doGet("whoami")
            .then(result => console.log('intervalID=%d, whoami ping request: %s', interval, result.login))
        }, 200000);
        return () => {
          console.log('clear intervalID: %d', interval);
          clearInterval(interval);
        }
      }
    }
  }, [initialized]);

    return (
      <>
        {
          authKey !== "" ? (
          <>
            <Greeting
              onWhoAmI={handleWhoAmI}
              docStat={docStat}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onStatusFilterChange={handleChangeStatusFilter}
              statusFilter={statusFilter}
              filterDocument={filterDocument}
            ></Greeting>

            <div className="hub-workspace">

            <Swiper spaceBetween={0} allowTouchMove={false} modules={[]} className="mySwiper"
              onSlideChange={ slide => {
                // console.log('onSlideChange: active=%d, previous=%d', slide.activeIndex, slide.previousIndex)
                const history = dataStorageContent.data.slide
                dataStorageContent.handleData({
                  slide: {
                    active: slide.activeIndex,
                    previous: slide.previousIndex,
                    previous2: history?.active,
                    previous3: history?.previous,
                  }
                })
              }}
            >
              <SwiperSlide>
                  <Items
                    onReachEnd={handleReachEnd}
                    onStatusFilterChange={handleChangeStatusFilter}
                    statusFilter={statusFilter}
                    items={items}
                    handleItemClick={handleItemClick}></Items>
              </SwiperSlide>
              <SwiperSlide className="hubItems">
                  <DocDetail data={currentDoc}
                    currentUser={currentUser}
                    onSignPage={handleSignPage}
                    onComment={handleComment}
                    onAddReconciliator={handleAddReconciliator}
                    reloadMainPage={reloadMainPage}
                  ></DocDetail>
              </SwiperSlide>

              <SwiperSlide>
                  <SignPanel
                    currentDoc={currentDoc}
                    handleDidSigned={handleMainPage}
                    dataStorageContent={dataStorageContent}
                  ></SignPanel>
              </SwiperSlide>

              <SwiperSlide>
                <SearchPanel
                    handleItemClick={handleItemClick}
                ></SearchPanel>
              </SwiperSlide>

              <SwiperSlide>
                <ResolutionPanel
                    currentDoc={currentDoc}
                ></ResolutionPanel>
              </SwiperSlide>

              <SwiperSlide>
                <ConsiderationResolutionPanel
                    currentDoc={currentDoc}
                    reloadMainPage={reloadMainPage}
                ></ConsiderationResolutionPanel>
              </SwiperSlide>

              <SwiperSlide>
                <FilterDocumentsPanel
                  handleFilter={filterDocumentSet}
                  reloadMainPage={reloadMainPage}
                  docStat={docStat}
                ></FilterDocumentsPanel>
              </SwiperSlide>

              {/* <SwiperSlide>
                  <UserPanel></UserPanel>
              </SwiperSlide> */}
            </Swiper>
            </div>
            <TaskBar onLogout={handleLogout}></TaskBar>
          </>
          ) : (
            <Swiper  modules={[]} className="mySwiper">
              <SwiperSlide>
                  <Login handleAuth={handleAuthenticate}></Login>
              </SwiperSlide>
            </Swiper>
          )
        }

      </>
    );
}

export default Screen;
