import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useRef } from 'react'

import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
// import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded'
import InputAdornment from '@mui/material/InputAdornment'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { Button } from '@mui/material'
import { doGet } from './Do'
import { useLoader } from "./Loader"
import { useSwiper } from 'swiper/react'
import { useDataStorage } from './DataStorage'
import SkeletonList from './SketetonList'
import FoundDocItem from './FoundDocItem'
import {useError} from './ErrorProvider'

import { notDraft } from './documentStates'
import ScrollUp from './ScrollUp'

const SearchPanel = ({...props}) =>
{
   const dataStorageContext = useDataStorage()
   const minSearchLength = 3
   const loaderContext = useLoader();
   const swiper = useSwiper()

   const [query, setQuery] = useState('')
   const [data, setData] = useState([])
   const [dataLoad, setDataLoad] = useState(false)
   const {onError} = useError()

   const pageSize = process.env.REACT_APP_PAGE_SIZE
   const [currentPage, setCurrentPage] = useState(0)

   const handleQuery = (e) => setQuery(e.target.value)

   const handleItemClick = item => {

      const target = dataStorageContext.data.target

      switch (target) {
         case 'Resolution':
            dataStorageContext.handleData({foundDoc: item})
            swiper.slideTo(1) // DocDetails
            break
         case 'docDetail':
            props.handleItemClick(item) // Screen.js
            break
         default:
            console.error("SeacrhPanel.handleItemClick:  target=%s, item=%O", target, item)
            break;
      }
   }

   const makeSearch = () => {
      setData([])
      setCurrentPage(0)
      if (!dataLoad && query.length >= minSearchLength )
         getResult()
   }

   const getResult = () => {
      loaderContext.onWait()
      setDataLoad(true)

      doGet(`ftsSearch&q=${query}&limit=${pageSize}&start=${currentPage}`)
         .then(resp => {
            if (dataStorageContext.data.showDoc === 'notDraft')
               resp = resp.filter(item => notDraft.includes(item.docState))

            if (currentPage === 0)
               setData([...resp])
            else
               setData([...data, ...resp])

            if (resp.length)
               setCurrentPage(currentPage + 1)
         })
         .catch(error => {
            onError(error)
         })
         .finally(() => {
            loaderContext.onResume()
            setDataLoad(false)
         })
   }


   useEffect(() => {
      setCurrentPage(0)
      if (query.length < minSearchLength ) {
         setData([])
         return
      }
   /*    const timeoutId = setTimeout(() => {
         loaderContext.onWait()
         setData([])
         setDataLoad(true)
         doGet(`ftsSearch&q=${query}`)
            .then(resp => {
               if (dataStorageContext.data.showDoc === 'notDraft')
                  resp = resp.filter(item => notDraft.includes(item.docState))
               setData(resp)
               loaderContext.onResume()
               setDataLoad(false)
            })
            .catch(error => {
               loaderContext.onResume()
               setDataLoad(false)
               onError(error);
            })
         }, 900)
      return () => clearTimeout(timeoutId) */
   }, [query])

   const searchRef = useRef()
   const scrollToRef = useRef()

   useEffect(() => {
      //
      const handleReachEnd = event => {
         const element = event.currentTarget
         if (element.offsetHeight + element.scrollTop >= element.scrollHeight - 1) {
            if (!dataLoad && query.length >= minSearchLength)
               getResult()
         }
      }

      const element = searchRef.current
      element && element.addEventListener('scroll', handleReachEnd)
      return () => element.removeEventListener('scroll', handleReachEnd)
   }, [currentPage, query])


   const style = {
      height: 'calc(100vh - 80px)',
      // border: '1px green dashed',
      paddingLeft: '.6em',
      paddingTop: '.6em',
      overflow: 'auto',
      width: '100vw',
   }

   return (
      <div
         style={style}
         ref={searchRef}
      >
         <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            ref={scrollToRef}
         >
            <Grid item xs={10}>
               <TextField
                  size='small'
                  label={`Пошук документів (min ${minSearchLength})`}
                  value={query}
                  onChange={handleQuery}
                  fullWidth
                  InputProps={{
                     endAdornment: (
                        <InputAdornment position="end">
                           {
                              query ?
                                 <ClearIcon
                                    onClick={e => {
                                       setQuery('')
                                       dataStorageContext.handleData({foundDoc: {}})
                                    }}
                                 ></ClearIcon>
                           : <SearchIcon />

                           }
                        </InputAdornment>
                     )
                 }}
               ></TextField>
            </Grid>

            <Grid item xs="auto">
               <Button
                  size="small"
                  variant="text"
                  color="inherit"
                  sx={{minWidth:'auto', m:0}}
                  onClick={ () => makeSearch() }
               >
                  {/* <ArrowForwardIosIcon /> */}
                  {/* <DoubleArrowIcon /> */}
                  <DoubleArrowRoundedIcon
                     fontSize='large'
                     sx={{color: 'gray'}}
                  ></DoubleArrowRoundedIcon>
               </Button>
            </Grid>

         </Grid>

         <Divider sx={{mt:1, mb:1}}/>

         <Grid
            container
            direction="column"
         >

         {
            data.length > 0 || !dataLoad ?

               data.map((item, key) => (
               <Grid
                  item
                  sx={{mb:0.1}}
                  key={key}
                  onClick={() => handleItemClick(item)}
               >
                  <FoundDocItem item={item} query={query} />

               </Grid>
            ))

            : <SkeletonList rows={3} />
         }

      </Grid>

         <ScrollUp refScrollUp={searchRef} scrollToRef={scrollToRef}></ScrollUp>

      </div>
   )
}

export default SearchPanel
