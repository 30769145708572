import React from "react"
import FormControl from '@mui/material/FormControl'
import { InputLabel, TextField } from '@mui/material'
import Grid from '@mui/material/Grid'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import InputAdornment from '@mui/material/InputAdornment'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'


const ResolutionPeriodicalPanel = (params) => {

   const {
      periodicalType, setPeriodicalType,
      periodicalBegin, setPeriodicalBegin,
      periodicalEnd, setPeriodicalEnd
   } = params

   const periodicalTypes = React.useMemo(() => {
      return [
         { id: 3000000002537, code: 'DAYLY', label: 'Щоденно' },
         { id: 3000000002538, code: 'WEEKLY', label: 'Щотижнево' },
         { id: 3000023911225, code: 'DECADE', label: 'Щодекади' },
         { id: 3000000002539, code: 'MONTHLY', label: 'Щомісячно' },
         { id: 3000000002540, code: 'QUARTERLY', label: 'Щоквартально' },
         { id: 3000000002541, code: 'SEMIANNUALLY', label: 'Щопівріччя' },
         { id: 3000000002542, code: 'ANNUALLY', label: 'Щорічно' }
      ]
   }, [])

   const handlePeriodicalType = event => setPeriodicalType(event.target.value)
   const handlePeriodicalBegin = value => setPeriodicalBegin(value)
   const handlePeriodicalEnd = value => setPeriodicalEnd(value)

   /*
   ***
   */
   return (

   <Grid
      container
      alignItems='center'
      justifyContent='space-between'
      alignContent='space-between'
      direction='row'
      sx={{pt: 1, pl: 1, textAlign: 'left'}}
      spacing={1.5}
   >

      <Grid item xs={11} size={'small'}>
         <FormControl required>
            <InputLabel id="periodicalType-label">Період</InputLabel>
            <Select
               size='small'
               fullWidth
               labelId="periodicalType-label"
               id="periodicalType"
               name="periodicalType"
               value={periodicalType}
               label="Період"
               onChange={handlePeriodicalType}
            >
            {
               periodicalTypes.map(item => (
                  <MenuItem key={item.id} value={item.code}>{item.label}</MenuItem>
               ))
            }
            </Select>
         </FormControl>
      </Grid>

      <Grid item xs={6}>
         <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDatePicker
               required
               id="periodicalBegin"
               name="periodicalBegin"
               closeOnSelect={true}
               label="Початок"
               inputFormat="DD.MM.YYYY"
               value={periodicalBegin}
               onChange={handlePeriodicalBegin}
               renderInput={(params) => <TextField {...params} size='small' required/>}
               componentsProps={{
                  actionBar: {
                     actions: [],
                  },
               }}
               InputProps={{
                  endAdornment : (
                     <InputAdornment position="end">
                        <CalendarMonthIcon />
                     </InputAdornment>
                  )
               }}
            />
         </LocalizationProvider>
      </Grid>

      <Grid item xs={6}>
         <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDatePicker
               required
               id="periodicalEnd"
               name="periodicalEnd"
               closeOnSelect={true}
               label="Кінець"
               inputFormat="DD.MM.YYYY"
               value={periodicalEnd}
               onChange={handlePeriodicalEnd}
               renderInput={(params) => <TextField {...params} size='small' required/>}
               componentsProps={{
                  actionBar: {
                     actions: [],
                  },
               }}
               InputProps={{
                  endAdornment : (
                     <InputAdornment position="end">
                        <CalendarMonthIcon />
                     </InputAdornment>
                  )
               }}
            />
         </LocalizationProvider>
      </Grid>


   </Grid>
   )
}

export default ResolutionPeriodicalPanel
