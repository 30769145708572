import React from 'react';

export const Executor = ({executor, my, currentExecutorID, last = true}) =>
{
    const koma = last ? '' : ', '
    const style = {}
    const executorCaption = executor.orgUnitEOSshortFIO || executor.plannedOrgUnitCaption || executor.caption.split("(")[0] || ''

    if (/* executor.mi_wfState === 'EXECUTED' */ ['EXECUTED', 'CLOSED'].includes(executor.mi_wfState))
        style.color = '#009b0f'
    else if (executor.mi_wfState === 'NEW')
        style.color = '#b69b0f'

    return (
        <span style={style}>{
            my ?
            <b>{executorCaption}</b>
            :
            executorCaption
        }
        {koma}
        </span>
    );
}
export default Executor;
