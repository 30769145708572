import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SyncIcon from '@mui/icons-material/Sync';
import {Button} from '@mui/material';

import ExecutorAvatar from './ExecutorAvatar';

const ExecutorsListTable = ({...params}) =>
{
   const {
      executorsList: rows = [],
      deleteExecutor,
      executorRoles,
      getExecutorsByExecItem,
      executorsEmail
   } = params

   return (
      <TableContainer component={Paper}>
      <Table>
         <TableHead>
            <TableRow>
               <TableCell>Виконавець</TableCell>
               <TableCell>Роль</TableCell>
               {/* <TableCell>Відп.</TableCell> */}
               <TableCell>
                  <Button
                     size="large"
                     variant="text"
                     color="inherit"
                     sx={{minWidth:'auto', m:0}}
                     onClick={() => getExecutorsByExecItem()}
                  >
                     <SyncIcon />
                  </Button>
               </TableCell>
            </TableRow>
         </TableHead>
         <TableBody>
         {rows?.map((row) => (
            <TableRow
               key={row.ID}
            >
               <TableCell component="td" scope="row">

                  <ExecutorAvatar
                     email={executorsEmail[row.orgUnitID]}
                     caption={row.caption}
                     responsible={row.respForExecItem}
                  ></ExecutorAvatar>
                  {row.respForExecItem ? <br/> : null}

                  <span>
                     {row.caption.match(/^(.+)\s(\(.+\))$/)[1]}
                  </span>
                  <br/>
                  <span style={{fontSize:'.5em', display:'block'}}>
                     {row.caption.match(/^(.+)\s(\(.+\))$/)[2]}
                  </span>
               </TableCell>
               <TableCell>
                  {executorRoles.find(item => item.code === row.execRole).caption}
               </TableCell>
               {/* <TableCell>
               {row.respForExecItem ? 'Так' : 'Ні'}
               </TableCell> */}
               <TableCell>
                  <Button
                     size="large"
                     variant="text"
                     color="inherit"
                     sx={{minWidth:'auto', m:0}}
                     onClick={() => deleteExecutor(row.ID)}
                  >
                     <DeleteForeverIcon />
                  </Button>
               </TableCell>
            </TableRow>
         ))}
         </TableBody>
      </Table>
   </TableContainer>
   )
}

export default ExecutorsListTable
