import React, { useEffect, useState } from 'react';
import Button from './Button';
import 'bootstrap/dist/css/bootstrap.css'
import { useAuth } from './AuthProvider';
import { useError } from './ErrorProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import {TextField} from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

export const Login =()=> {
    var {key, onLogin, onLogout} = useAuth();
    var [user,setUser] = useState("");
    const [waiting,setWaiting] = useState(false);

    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);


    var {error, onError} = useError();
    const location = useLocation();
    const navigate = useNavigate();


    //---read login from store
    useEffect(()=>{
        var o = JSON.parse(localStorage.getItem("user"))?.name
        if(o !== undefined){
            setUser(o);
        }
    }, []);

    const handleChangePassword = (e) =>
    {
        setPassword(e.target.value);
    }

    const handleClickShowPassword = () => {
        let n = !showPassword;
        setShowPassword(n);
    };

    const handleLogin = ()=>
    {
            setWaiting(true);
            window.setTimeout(()=>{
                var u = user;
                var p = password;

                onLogin(u,p)
                .then((key)=>{
                    let fromPath = location.state?.from?.pathname || "/";
                    navigate(fromPath);
                })
                .catch(ex=>{
                    var message = typeof ex == "string" ? ex:ex.message;
                    onError(message);
                })
                .finally(()=>{
                    setWaiting(false);
               });
            },100)
    }

    const handleUser = (e) => {
        setUser(e.target.value);
        localStorage.setItem("user",JSON.stringify({name:e.target.value}));
    }

    return (
        <div className="loginPage">
            <div className='loginPanel'>
                <form>
                    <TextField id="megapolisName" margin="normal" fullWidth
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                        value={user}
                        onChange={handleUser}
                        label="Користувач"
                        required
                    />

                    <TextField id="megapolisPassword" margin="normal" fullWidth
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        value={password}
                        onChange={handleChangePassword}
                        label="Пароль"
                    />

                    <Button
                        onClick={() => { handleLogin() }}
                        name="Увійти"
                        wait={waiting}>
                    </Button>

                    <div className='hub-white'>v.0.0.76</div>

                </form>
            </div>
        </div>
        );
}

export default Login;
