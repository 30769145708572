import  * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {doGet} from './Do';
import {useError} from './ErrorProvider';
import { ClosedCaption } from '@mui/icons-material';
import {UserPhoto} from './UserPhoto';
import Button from './Button';


export const AddReconciliator = ({onSelect})=>{

    var [users,setUsers] = React.useState([]);
    var [currText,setCurrText] = React.useState("");
    var [value, setValue] = React.useState(null);
    var {error, onError} = useError();
    var [showPanel, setShowPanel] = React.useState(false);

    const searchReconciliators = (e, text) =>
    {
      setCurrText(text);
      if(text.length > 2){
        doGet(`getStaffUnit&q=${text}`)
        .then(data => {
            var options = data.map(item=>{return {
              id: item.ID,
              label: item.caption,
              email: item.email,
            }})
           setUsers(options);
        })
        .catch(error => 
          {
              onError(error);
          })    
      }
    }

    const handleChangeValue = (e,val) => 
    {
      setValue(val);
    }

    const handleWasSelected = ()=>
    {
      if(value)
      {
        onSelect(value.id);
        setShowPanel(false);
      }
    }

    return (
      <div>
        {showPanel ?
          (
          <div className="hub-bordered-panel">
            <Autocomplete
            id="add-reconciliator"            
            options={users}
            autoHighlight
            value={value}
            onChange={handleChangeValue}
            inputValue={currText}
            onInputChange={searchReconciliators}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <UserPhoto email={option.email} />
                {option.label}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Додати візуючого"
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
              />
            )}/>
            <Button onClick={handleWasSelected} name="Додати" />
            <Button onClick={() => setShowPanel(!showPanel)} name="Закрити" />
            </div>
          ):
          (
            <Button onClick={()=> setShowPanel(!showPanel)} name="Додати візуючого" />
          )
        }
      </div>
    )
}

export default AddReconciliator;