import React from 'react';
import '../css/do.css';
import { UserPhoto } from './UserPhoto';

class Reconciliator extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {}
    render() {

        ///---all action was completed. Show reconciliator with action date
        if(this.props.data.recParticipantState != "ONRECONCILIATION")
        {
            return(
                <div className={this.props.data.executionDate != null ? 'reconciliationPosition hubPanel' : 'reconciliationPosition hubPanel'}>
                    <div>
                        <UserPhoto email={this.props.data.staffUnitEOSEmail}/>
                    </div>
                    <div>
                        <div className={this.props.data.executionDate != null
                            ? (this.props.data.recParticipantState == "APPROVED" ? "reconciliationCompleted" : "reconciliationRejected")
                            : ""}>

                            {['WAITFORRECONCILIATION', 'ONRECONCILIATION'].includes(this.props.data.recParticipantState)
                                ? this.props.data.staffUnitCaption
                                : this.props.data.plannedOrgUnitCaption || this.props.data.staffUnitEOSCaption}

                            <div className='reconciliationDate'>
                                ({this.props.data.recStageKindName} {this.props.data.executionDate != null ? `${(new Date(this.props.data.executionDate)).toLocaleString('uk-UA')}` : ''})
                            </div>
                            <div>
                                Коментар: {this.props.data.executionComment}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        ///---if not all action completed - show current user action
        if(this.props.data.recParticipantID == this.props.currentRecID)
        {
            return(
                <div className='currentReconciliation hubPanel'>
                    <div>
                        <UserPhoto email={this.props.data.staffUnitEOSEmail}/>
                    </div>
                    <div>
                        {this.props.data.staffUnitCaption}
                        <div className='reconciliationDate'>
                            ({this.props.data.recStageKindName})
                        </div>
                    </div>
                </div>
            );
        }else{
            return (
                <div className={this.props.data.executionDate != null ? 'reconciliationPosition hubPanel' : 'reconciliationPosition hubPanel'}>
                    <div>
                        <UserPhoto email={this.props.data.staffUnitEOSEmail}/>
                    </div>
                    <div>
                        <div className={this.props.data.executionDate != null
                            ? (this.props.data.recParticipantState == "APPROVED" ? "reconciliationCompleted" : "reconciliationRejected")
                            : ""}>

                            {['WAITFORRECONCILIATION', 'ONRECONCILIATION'].includes(this.props.data.recParticipantState)
                                ? this.props.data.staffUnitCaption
                                : this.props.data.plannedOrgUnitCaption || this.props.data.staffUnitEOSCaption}

                            <div className='reconciliationDate'>
                                ({this.props.data.recStageKindName} {this.props.data.executionDate != null ? `${(new Date(this.props.data.executionDate)).toLocaleString('uk-UA')}` : ''})
                            </div>
                            <div>
                                Коментар: {this.props.data.executionComment}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Reconciliator;