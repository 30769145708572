import React from "react"
import { useDataStorage } from "./DataStorage"

/**
 * @todo прозорий та зрозумілий перехід між сторінками. Зараз це просто Жах!
 */
const ButtonPrev = ({...props}) => {

   const swiper = document.querySelector(props.swiperId).swiper

   const dataStorageContext = useDataStorage()
   const slide = dataStorageContext.data.slide
   // console.log("ButtonPrev %O", slide)

   const handleClick = () => {

      let previousIndex

      if (swiper.activeIndex === 3 && swiper.previousIndex === 1) // Search from Resolution
         previousIndex = swiper.previousIndex

      if (swiper.activeIndex === 4 && swiper.previousIndex === 1) // ResolutionPanel
         previousIndex = swiper.previousIndex

      if (swiper.activeIndex === 1 && swiper.previousIndex === 3) // DocDetail from Search
         previousIndex = swiper.previousIndex

      if (swiper.activeIndex === 1 && swiper.previousIndex === 3 && slide?.previous2 === 4 && slide?.previous3 === 1) // DocDetail from ResolutionPanel
         previousIndex = 0

      if (swiper.activeIndex === 3 && swiper.previousIndex === 0) // Seacrh from main page
         props.page = 0

      if (swiper.activeIndex === 6 && swiper.previousIndex === 0) // FilterDocumentsPanel from main page
         props.page = 0

      if(props.page) {
         let p = parseInt(props.page)
         swiper.slideTo(p)
      } else {
         swiper.slidePrev()
      }
      props.onPageChange( previousIndex || props.page )
   }

   return (
      <img src="/images/leftarrow.svg" width="30" height="30" onClick={handleClick} alt="Go back" />
   )
}

export default ButtonPrev
