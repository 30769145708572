import React from 'react';
import { doGet } from './Do';
import ButtonUnchecked from './ButtonUnchecked';
import ButtonPrev from './ButtonPrev.jsx';
import { useError } from './ErrorProvider'

import { Button } from '@mui/material'
import { Box } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useState } from 'react';

import { useDataStorage } from './DataStorage'
import { useEffect } from 'react';

const Greeting = ({...props}) => {

   const dataStorageContext = useDataStorage()
   const onError = useError()

   const stateInit = {
      userName: "",
   }
   const [state, setState] = useState(stateInit)
   const handleData = newState => {
      setState(prevState => ({
         ...prevState,
         ...newState
      }))
   }

   useEffect(() => {
      //--set greeting message
      doGet("whoami")
      .then(currentUser => {
         let name = currentUser.employeeFullFIO.split(' ').length > 2
            ? currentUser.employeeFullFIO.split(' ')[1] + " " + currentUser.employeeFullFIO.split(' ')[2]
            : currentUser.employeeFullFIO

         handleData({userName: name})
         props.onWhoAmI(currentUser)
         dataStorageContext.handleData({currentUser: currentUser})

         //---read status processed/all documents
         // doGet("getRecStat").then(data => handleData(...data))
      })
      .catch(err => onError(err))
   }, [])

   const handlePageChange = (page) => {
      props.onPageChange(page)
   }

   const handleChangeStatusFilter = () => {
      props.onStatusFilterChange()
   }

   //X//
   const [docStat, docStatSet] = useState({inWork: 0, all:0})
   useEffect(() => {
      switch (props.filterDocument) {
         case 'showConsider':
           docStatSet({inWork: props.docStat.consideration.work, all: props.docStat.consideration.all})
           break;
         case 'showExecution':
            docStatSet({inWork: props.docStat.execution.work, all: props.docStat.execution.all})
           break;
         case 'showReconcil':
            docStatSet({inWork: props.docStat.reconciliation.work, all: props.docStat.reconciliation.all})
           break;
         case 'showInfo':
            docStatSet({inWork: props.docStat.info.work, all: props.docStat.info.all})
           break;
         default:
            docStatSet({inWork: props.docStat.work, all: props.docStat.all})
           break;
       }
      // console.log("Greeting.docStat = %O", props.docStat)
      // console.log("Greeting.filterDocument = %O", props.filterDocument)
   }, [props.docStat, props.filterDocument])
   //X//

   return (
         <div className="hubSearchBox" id="hubSearchBox">
            {
               props.currentPage === 0 ?

                  <Box
                     id="greetingBox"
                     component="div"
                     sx={{
                        display: 'flex',
                        justifyContent: "space-between",
                        alignItems: "center"
                     }}
                  >
                     Вітаю, {state.userName}

                     <ButtonUnchecked
                        docStat={docStat}
                        statusFilter={props.statusFilter}
                        onChange={handleChangeStatusFilter}
                     ></ButtonUnchecked>

                     <Button
                        variant="text"
                        aria-label="Фільтрація документів"
                        color={!props.filterDocument || props.filterDocument === 'showAll' ? 'inherit' : 'error'}
                        sx={{
                           p: 0,
                           minWidth: 'auto'
                        }}
                        onClick={e => {
                           props.onPageChange(6)
                        }}
                     >
                        <FilterAltOutlinedIcon></FilterAltOutlinedIcon>
                     </Button>


                     <Button
                        variant="text"
                        aria-label="Пошук документів"
                        color='inherit'
                        sx={{
                           p: 0,
                           minWidth: 'auto'
                        }}
                        onClick={e => {
                           dataStorageContext.handleData({
                              showDoc: 'all',
                              target: 'docDetail'
                           })
                           props.onPageChange(3)
                        }}
                     >
                        <SearchIcon></SearchIcon>
                     </Button>

                  </Box>

                  :

                  <ButtonPrev swiperId=".mySwiper" page={props.currentPage - 1} onPageChange={handlePageChange}></ButtonPrev>

            }

         </div>
      )
}

export default Greeting
