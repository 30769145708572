import React from 'react';
import {Spinner} from "react-bootstrap";

class Button extends React.Component {
    constructor(props) {
        super(props);

        this.handleAuthenticate = this.handleAuthenticate.bind(this);
    }
    state = {  
           }

    handleAuthenticate(){
        if(this.props.wait == undefined || this.props.wait == false)
        {
            this.props.onClick();
        }
    }

    componentDidMount(){
    }

    render() { 
        if(this.props.disabled)
        {
            return (  
                <div>
                    <div className="hub-button hub-grey" >
                        {this.props.wait != undefined && this.props.wait &&  (<Spinner variant='dark' animation='border' size='sm'/>)}
                        {this.props.name}
                    </div>
                </div>
            );
        }else{
            return (  
                <div>
                    <div className="hub-button"  onClick={this.handleAuthenticate}>
                        {this.props.wait != undefined && this.props.wait &&  (<Spinner variant='dark' animation='border' size='sm'/>)}
                        {this.props.name}
                    </div>
                </div>
            );
        }
    }
}
 
export default Button;