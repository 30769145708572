import React from 'react';
import {Routes,Route,NavLink, Navigate, useNavigate, useLocation} from 'react-router-dom';
import { useAuth } from './AuthProvider';
import {useParams} from 'react-router';

export const ProtectedRoute = ({children})=>
{
    const {key} = useAuth();
    const location = useLocation();


    if (!key) {
        return <Navigate to="/login" replace state={{ from: location}} />;
    }
    return children;
}
