import React from 'react';
import Executor from './Executor';

export const Executors = ({currentUser, data, role, currentExecutorID}) =>
{
   return(
   <>
      {
         data ?
         data.filter(x=>x.execRole === role).map((executor, index, array) => {
            const last = index === array.length - 1
            return(
               <span key={executor.ID} className="marginLeft">
                  <Executor my={currentExecutorID === executor.ID} executor={executor} currentExecutorID={currentExecutorID} last={last}></Executor>
               </span>
            );
         })
         :""
      }
   </>
   );
}

export default Executors;