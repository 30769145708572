import React from "react"
import { useRef } from "react"
import { useState } from "react"
import LaunchIcon from '@mui/icons-material/Launch';

import moment from "moment";

import {getDocKind, getDocState} from './Do.js';
import { processed } from './documentStates'
import useOnScreen from "./useOnScreen.jsx";
import SearchFragment from "./SearchFragment.jsx";

const FoundDocItem = ({...props}) => {
   const {item, query} = props

   let docTypeStyle = "execution_doc_type"
   let requireProcessStyle = "hubDescription";
   let imgSrc = '/images/checked.png'

   if (getDocKind(item) === "EXECUTION")
       docTypeStyle = "execution_doc_type";
   else if (getDocKind(item) === "RECONCILIATION")
       docTypeStyle = "reconcil_doc_type";

   if (!processed.includes(item.docState)) {
      requireProcessStyle = "unprocessedClass hubDescription"
      imgSrc = '/images/unchecked.png'
   }

   const [searchDone, setSearchDone] = useState(false)

   const ref = useRef()
   const isVisible = useOnScreen(ref)

   // const LasySearch = React.lazy(() => import('./SearchFragment'))

   return (

   <div className={docTypeStyle} style={{marginLeft:0}} ref={ref}>
      <div className={requireProcessStyle}>
         <img src={imgSrc} className="hubTaskPictogram" alt={item.docState} />
         <span> {item.mi_tr_shortText}</span>
      </div>
      <div className="hubDescription">
         <b style={{verticalAlign: "textTop", marginRight:'2em'}}>{item.docCaption}</b>
         <a href={item.href} target="_blank" rel="noopener noreferrer" onClick={event=>event.stopPropagation()}>
            <LaunchIcon fontSize="small" color="action" />
         </a>
      </div>
      <div className="hubSubDescription">{item.docKind} | {item.docStateName} </div>
      <div className="hubSubDescription">{moment(item.docCreateDate).format('LL')}| {item.createdCaption}</div>
      <div className="hubSubDescription">
         {/* <Suspense fallback={<div>Завантаження…</div>}>
            <LasySearch item={item} query={query} setSearchDone={setSearchDone} />
         </Suspense> */}
         { isVisible || (!isVisible && searchDone) ?  <SearchFragment item={item} query={query} setSearchDone={setSearchDone}/> : null}
      </div>
   </div>

   )
}

export default FoundDocItem
