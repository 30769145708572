import React from 'react';

export const UserPhoto = ({email}) =>
{
    const imgUrl = email ? process.env.REACT_APP_MAP + "/api/userphoto/" + email : '/images/incognito-icon-21.jpg'

    const divStyle = {
        backgroundImage: 'url(' + imgUrl + ')',
    }

    return (
        <div className="roundPhoto" style={divStyle}></div>
    );
}
