import React from 'react'
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'

const StyledBadge = styled(Badge)(({ theme }) => ({
   '& .MuiBadge-badge': {
      backgroundColor: '#4f86f7',
      color: '#4f86f7',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
         position: 'absolute',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         borderRadius: '50%',
         border: '1px solid currentColor',
         content: '""',
      },
   },
}))

const photoURL = process.env.REACT_APP_MAP + "/api/userphoto/"


const ExecutorAvatar = ({...params}) =>
{
   const {
      caption,
      email,
      responsible,
      style = {}
   } = params

   const avatar = <Avatar
      sx={{
         borderWidth: 1,
         borderColor: '#cfcdcd',
         borderStyle:'solid',
         ...style,
      }}
      alt={email}
      src={email ? photoURL + email : null}
   >
      {caption.substring(0, 3)}
   </Avatar>


   return (

   !!responsible ?

      <StyledBadge
         overlap="circular"
         anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
         }}
         variant="dot"
      >
         {avatar}
      </StyledBadge>

   : avatar

   )
}

export default ExecutorAvatar
