import React from "react"
import Grid from "@mui/material/Grid"
import FormControl from "@mui/material/FormControl"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"


const FilterDocumentsPanel = (params) => {

	const {handleFilter, reloadMainPage, docStat} = params

	const initFilter = /* JSON.parse(localStorage.getItem("filterDocument")) || */ {show: 'showAll'}
	const [formValues, setFormValues] = React.useState(initFilter)

	const handleInputChange = (e) => {
		const { name, value } = e.currentTarget
		setFormValues({
			/* ...formValues,
			[name]: value, */
			show: name
		})
		handleFilter(name)
		setTimeout(() => reloadMainPage(), 100) // Щоб встигав спрацьовувати Screen -> getMethodSet()
	}

	// const setColor = elem => formValues.show === elem ? 'error' : 'inherit'

	const setActive = elem => formValues.show === elem ? {borderWidth: 2, fontWeight: 'bold'} : {borderWidth: 1, fontWeight: 'inherit'}

	React.useEffect(() => {
		// console.log("formValues=%O", formValues)
		// localStorage.setItem('filterDocument', JSON.stringify(formValues))
	}, [formValues])

	/*
	***
	*/
	return (

		<div style={{width: '100vw', overflow: 'auto', height: 'calc(100vh - 80px)'}}>

		<Grid
			container
			alignItems='center'
			justifyContent='space-between'
			alignContent='space-between'
			direction='row'
			sx={{pt: 2, pl: 2, pr: 2, textAlign: 'center'}}
			spacing={3}
		>

		<Grid item xs={12}>
			<Typography variant="h6" align='center'>
				Фільтрація документів
			</Typography>
		</Grid>

		<Grid item xs={12}>
			<FormControl fullWidth>
			<Button
				name="showAll"
				size="large"
				variant="outlined"
				// color={setColor('showAll')}
				color='inherit'
				sx={{textTransform: 'none', ...setActive('showAll')}}
				onClick={handleInputChange}
			>
				Показати всі<br/>({docStat.work}/{docStat.all})
			</Button>
			</FormControl>
		</Grid>

		<Grid item xs={6}>
		<FormControl fullWidth>
			<Button
				name="showReconcil"
				size="large"
				variant="outlined"
				// color={setColor('showReconcil')}
				sx={{textTransform: 'none', color: '#215968', borderColor: '#215968', ...setActive('showReconcil')}}
				onClick={handleInputChange}
			>
				На узгодження<br/>({docStat.reconciliation?.work}/{docStat.reconciliation?.all})
			</Button>
			</FormControl>
		</Grid>

		<Grid item xs={6}>
		<FormControl fullWidth>
			<Button
				name="showExecution"
				size="large"
				variant="outlined"
				// color={setColor('showExecution')}
				sx={{textTransform: 'none', color: '#004b88', borderColor: '#004b88', ...setActive('showExecution')}}
				onClick={handleInputChange}
			>
				На виконання<br/>({docStat.execution?.work}/{docStat.execution?.all})
			</Button>
			</FormControl>
		</Grid>

		<Grid item xs={6}>
		<FormControl fullWidth>
			<Button
				name="showConsider"
				size="large"
				variant="outlined"
				// color={setColor('showConsider')}
				sx={{textTransform: 'none', color: '#5f5602', borderColor: '#5f5602', ...setActive('showConsider')}}
				onClick={ handleInputChange}
			>
				На розгляд<br/>({docStat.consideration?.work}/{docStat.consideration?.all})
			</Button>
			</FormControl>
		</Grid>

		<Grid item xs={6}>
			<FormControl fullWidth>
			<Button
				name="showInfo"
				size="large"
				variant="outlined"
				// color={setColor('showInfo')}
				sx={{textTransform: 'none', color: '#004b88', borderColor: '#004b88', ...setActive('showInfo')}}
				onClick={handleInputChange}
			>
				Для інформації<br/>({docStat.info?.work}/{docStat.info?.all})
			</Button>
			</FormControl>
		</Grid>

		</Grid>

		</div>

	)
}

export default FilterDocumentsPanel
